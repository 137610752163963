const es = {
  label: {
    //-----------------------------------------------------------------------------------------------------------
    //ETIQUETAS COMPUESTAS
    //-----------------------------------------------------------------------------------------------------------
    selectedQuantity:"CANTIDAD SELECCIONADA",
    quantityDispached:"CANTIDAD A DESPACHAR",
    newDispatchBl: 'NUEVO DESPACHO DEL BL',
    editDispatchBl: 'DESPACHO DEL BL',
    errorRegistration: "ERROR AL REGISTRAR",
    blsDetailsReceived:"DETALLES BLS RECEPCIONADOS",
    declaredHouse:"HOUSE DECLARADO",
    totalHouseReceived:"TOTAL UNIDAD DE HOUSE RECEPCIONADAS",
    totalPlannedHouse:"TOTAL UNIDAD DE HOUSE PLANIFICADAS",
    destinationLocation: "UBICACIÓN DESTINO",
    newDeconsolidationEvent: 'NUEVO EVENTO DE DESCONSOLIDACIÓN',
    editDeconsolidationEvent: 'EDITAR EVENTO DE DESCONSOLIDACIÓN',
    deconsolidated: 'DESCONSOLIDADO',
    deconsolidatedList: 'LISTA DE EVENTOS DE DESCONSOLIDADO',
    dispachedWeight: 'PESO DESPACHADO',
    receivedWeight: 'PESO RECEPCIONADO',
    plannedWeight: 'PESO PLANIFICADO',
    dispachedVolumen: 'VOLUMEN DESPACHADO',
    receivedVolumen: 'VOLUMEN RECEPCIONADO',
    plannedVolumen: 'VOLUMEN PLANIFICADO',
    dispachedQuantity: 'CANTIDAD DESPACHADA',
    CertifiedQuantity: 'CANTIDAD CERTIFICADA',
    CertifiedVolumen: 'VOLUMEN CERTIFICADO',
    CertifiedWeight: 'PESO CERTIFICADO',
    originBl: "BL ORIGEN",
    blsInventory:"BLS EN INVENTARIO",
    CertificationDocumentDate:"FECHA CERTIFICACIÓN",
    indicateQuantityAccesory:"DEBE INDICAR CANTIDAD CUANDO SELECCIONE ACCESORIO",
    indicateQuantitYPosition:"DEBE INDICAR CANTIDAD CUANDO SELECCIONE POSICIÓN",
    quantityOfAccessoryBeNegative:"CANTIDAD DE ACCESORIO NO PUEDE SER NEGATIVO",
    notSerialized:"NO SERIALIZADOS",
    Master: 'MASTER',
    automaticCalculation:'CÁLCULO AUTOMÁTICO',
    newActivity: 'NUEVA ACTIVIDAD PORTUARIA',
    modifActivity: 'EDITAR ACTIVIDAD PORTUARIA',
    registerActivity: 'REGISTRAR ACTIVIDAD PORTUARIA',
    portActivity: 'ACTIVIDAD PORTUARIA',
    nameActivity: 'NOMBRE ACTIVIDAD PORTUARIA',
    listActivity: 'LISTADO DE ACTIVIDADES PORTUARIAS',
    listBrand: 'LISTADO DE MARCAS Y MODELOS',
    listScale: 'LISTADO DE BÁSCULAS',
    listCrane: 'LISTADO DE TIPOS DE GRUAS',
    cranesTypesGeneralList: 'LISTADO GENERAL DE TIPOS DE GRUAS',
    listLost: 'LISTADO DE TIEMPOS PERDIDOS',
    listLostTime: 'LISTADO DE TIEMPOS PERDIDOS',
    listType: 'LISTA DE TIPOS',
    listStowage: 'LISTADO DE ESTIBA',
    vesselsGeneralList: 'LISTADO GENERAL DE BUQUES',
    listVessel: 'LISTADO DE BUQUES',
    portList: 'LISTADO DE PUERTOS',
    berthList: 'LISTADO DE MUELLES',
    companyBranchList: 'LISTADO DE EMPRESAS Y SUCURSALES',
    locationList: 'LISTADO DE UBICACIÓNES',
    moduleList: 'LISTADO DE MODULOS',
    roleList: 'LISTADO DE ROLES',
    generalRoleList: 'LISTADO DE ROLES GENERALES',
    specificRoleList: 'LISTADO DE ROLES ESPECIFICOS',
    generalRole: 'ROL GENERAL',
    specificRole: 'ROL ESPECÍFICO',
    moduleRoleList: 'LISTADO DE MÓDULOS POR ROL',
    portName: 'NOMBRE PUERTO',
    portCode: 'CODE PUERTO',
    listTypeVessel: 'LISTADO DE TIPOS DE BUQUE',
    lostTimeType: 'TIPO DE TIEMPO PERDIDO',
    lostTimeTypeName: 'NOMBRE DE TIPO DE TIEMPO PERDIDO',
    lostTimeName: 'NOMBRE DE TIEMPO PERDIDO',
    TdrCode: 'CÓDIGO TDR',
    craneType: 'TIPO DE GRUA',
    craneTypeName: 'NOMBRE DEL TIPO DE GRUA',
    craneName: 'NOMBRE GRUA',
    vesselType: 'TIPO DE BUQUE',
    vesselName: 'BUQUE',
    vesselTypeName: 'NOMBRE TIPO DE BUQUE',
    vesselTypeDescription: 'DESCRIPCION TIPO DE BUQUE',
    vesselCrane: 'GRUA BUQUE',
    moduleType: 'TIPO MODULO',
    maxHeigth: 'ALTURA MAXIMA',
    maxWeight: 'PESO MAXIMO',
    maxRange: 'ALCANCE MAXIMO',
    stowageWorkShift: 'TURNO ESTIBA',
    workShifts: 'TURNOS DE TRABAJO',
    workShift: 'TURNO DE TRABAJO',
    WorkShift: 'TURNO',
    shiftName: 'NOMBRE TURNO',
    turnName: 'NOMBRE TURNO',
    fromHour: 'HORA DESDE',
    hourFrom: 'HORA DESDE',
    DFrom: 'F/H DESDE',
    toHour: 'HORA HASTA',
    hourTo: 'HORA HASTA',
    DTo: 'F/H HASTA',
    startHour: 'HORA DESDE',
    endHour: 'HORA HASTA',
    stowageTurn: 'TURNO ESTIBA',
    stowageMov: 'MOVIMIENTOS DE ESTIBA',
    stowageMovement: 'MOVIMIENTO ESTIBA',
    stowageMovementTransaction: 'TRANSACCIÓN MOVIMIENTO ESTIBA',
    stowageRole: 'ROL ESTIBA',
    stowageSupplies: 'ALMACÉN DE SUMINISTROS',
    transactionMovs: 'TRANSACCIONES DE MOVIMIENTOS',
    transactionMov: 'TRANSACCIÓN DE MOVIMIENTO',
    movementName: 'NOMBRE DE MOVIMIENTO',
    movementType: 'TIPO DE MOVIMIENTO ',
    transactionName: 'NOMBRE DE TRANSACCION',
    transactionType: 'TIPO DE TRANSACCION',
    supplieName: 'NOMBRE INSUMO',
    groupName: 'NOMBRE GRUPO',
    groupDescription: 'DESCRIPCIÓN GRUPO',
    lostTime: 'TIEMPO PERDIDO',
    hatchCover: 'TAPA',
    hatchCoverName: 'NOMBRE TAPA',
    hatchCoverId: 'IDENTIFICACIÓN TAPA',
    hatchCoverType: 'TIPO DE TAPA',
    holdName: 'NOMBRE BODEGA',
    underCover: 'BAJO CUBIERTA',
    dustCover: 'SOBRE CUBIERTA',
    availableAssign: 'DISPONIBLE PARA ASIGNAR',
    vesselInfo: 'DATOS DEL BUQUE',
    vesselDocumentation: 'DOCUMENTACIÓN DEL BUQUE',
    onDeck: ' EN CUBIERTA',
    Outside: 'EXTERIOR',
    Inside: 'INTERIOR',
    chooseFile: 'SELECCIONE ARCHIVO',
    bulkLoad: 'CARGA MASIVA',
    seeFormat: 'VER FORMATO',
    berthLine: 'LINEA DE ATRAQUE',
    associatedLine: 'LINEAS ASOCIADAS',
    associatedPort: 'PUERTO ASOCIADO',
    associatedRol: 'ROL ASOCIADO',
    relatedLine: 'LINEAS RELACIONADAS',
    representativeLine: 'LINEA REPRESENTANTE',
    initialDate: 'FECHA INICIAL',
    finalDate: 'FECHA FINAL',
    findContainersKey:'UBICA LOS CONTENEDORES CON UNA CLAVE DE BUSQUEDA',
    findBlKey:'UBICA LOS BL CON UNA CLAVE DE BUSQUEDA',
    startDate: 'FECHA INICIO',
    endDate: 'FECHA FINAL',
    events: 'EVENTOS',
    date: 'FECHA',
    bulkCargo: 'MERCANCIA DE LA CARGA GRANEL',
    originPort: 'PUERTO DE ORIGEN',
    destinationPort: 'PUERTO DESTINO',
    arrivalTime: 'HORA DE ARRIBO',
    arrivalDate: 'FECHA DE ARRIBO',
    sailTime: 'HORA DE ZARPE',
    departureTime: 'HORA DE ZARPE',
    departureDate: 'FECHA DE ZARPE',
    basicData: 'DATOS BÁSICOS',
    mainPhone: 'TELÉFONO PRINCIPAL',
    secondaryPhone: 'TELÉFONO SECUNDARIO',
    mainEmail: 'CORREO PRINCIPAL',
    secondaryEmail: 'CORREO SECUNDARIO',
    badFormat: 'FORMATO INCORRECTO',
    businessProcess: 'PROCESO DE NEGOCIO',
    masterDirectory: 'DIRECTORIO MAESTRO',
    menuIdentifier: 'IDENTIFICADOR DEL MENU',
    proceduralManual: 'MANUAL DE PROCEDIMIENTO',
    userManual: 'MANUAL DE USUARIO',
    user: 'USUARIO',
    userId: 'CI USUARIO',
    userList: 'LISTA DE USUARIOS',
    userType: 'TIPO DE USUARIO',
    userTypeName: 'NOMBRE DE TIPO DE USUARIO',
    enterSearch: 'INGRESE BUSQUEDA',
    accessRoute: 'RUTA DE ACCESO',
    interfaceAction: 'ACCIÓN QUE EJECUTARÁ LA INTERFAZ',
    noData: 'NO EXISTEN REGISTROS',
    recordsPerPage: 'REGISTROS POR PAGINA',
    perPage: ' POR PAGINA',
    noResultsFound: 'NO SE HAN ENCONTRADO RESULTADOS',
    noRecordsAvailble: 'NO HAY REGISTROS DISPONIBLES',
    routeDash: 'RUTA DASH INICIO',
    route: 'RUTA',
    unitSize: 'UNIDAD DE MEDIDA',
    sizeUnit: 'UNIDAD DE MEDIDA',
    measureUnit: 'UNIDAD DE MEDIDA',
    measureUnitType: 'TIPO UNIDAD DE MEDIDA',
    gpoSupply: 'GRUPO DE INSUMO',
    payrollType: 'TIPO DE NÓMINA',
    employeeRole: 'EMPLEADO POR ROL',
    employeesRole: 'EMPLEADOS POR ROL',
    //DIEGO
    transversalView: 'VISTA TRANSVERSAL',
    previouslyAssigned: 'ASIGNADO PREVIAMENTE',
    associateBayPair: 'ASOCIAR BAY PAR',
    baySelected: 'BAY SELECCIONADO',
    notAvailable: 'NO DISPONIBLE',
    bay: 'BAY',
    tierRows: 'TIER-ROWS',
    positions: 'POSICIONES',
    download: 'DESCARGAR',
    atLeast: 'POR LO MENOS',
    selectIt: 'SELECCIONAR',
    holdType: 'TIPO DE BODEGA',
    changeStatusQuestion: '¿Esta seguro de realizar el cambio de status para el registro',
    changeViewQuestion: '¿Esta seguro de realizar el cambio de vista para el registro',
    changeElementQuestion: '¿Esta seguro de realizar el cambio de elemento para el registro',
    deleteQuestion: '¿Esta seguro de eliminar el registro',
    fileSizeExceedsAllowedLimit: 'El tipo de archivo no esta permitido.',
    fileTypeIsNotAllowed: 'El tipo de archivo no esta permitido.',
    touchToSelectPicture: 'TOQUE PARA SELECCIONAR FOTO',
    clickHereToSelectPicture: 'Click aqui para seleccionar una <br>FOTO',
    deviceDoesNotSupportFileUploads: 'Dispositivo no soporta la subida de archivos.',
    operationCancelled: 'OPERACIÓN CANCELADA',
    operationType: 'TIPO DE OPERACIÓN',
    CProa: 'C.PROA',
    CPopa: 'C.POPA',
    draftForward: 'CALADO PROA',
    draftAft: 'CALADO POPA',
    bowDraft: 'CALADO PROA',
    sternDraft: 'CALADO POPA',
    summerDWT: 'SUMMERDWT',
    LWL: "LWL",
    deckCrane: 'GRUA A BORDO',
    fuelOilCapacity: "CAPACIDAD DE COMBUSTIBLE",
    documentDescription: "DESCRIPCIÓN DEL DOCUMENTO",
    grainCapacity: "CAPACIDAD GRANO",
    baleCapacity: "CAPACIDAD BALA",
    documentType: 'TIPO DE DOCUMENTO',
    document: 'DOCUMENTO',
    removeDocument: 'ELIMINAR DOCUMENTO',
    transacType: 'TIPO DE TRANSACCION',
    details: 'DETALLES',
    cancelUpload: 'CANCELAR CARGA',
    mustSelectDocType: 'Debe seleccionar un Tipo de Documento.',
    docTypeInvalid: 'EL FORMATO DEL DOCUMENTO NO ES VALIDO.',
    information: 'INFORMACIÓN',
    alerts: 'ALERTAS',
    errors: 'ERRORES',
    navReports: 'REPORTES',
    navConfig: 'CONFIGURACIÓN',
    navViews: 'VISTAS',
    views: 'VISTAS',
    navPersonalReport: 'PERSONALIZAR REPORTES',
    values: 'VALORES',
    viewType: 'TIPO DE VISTA',
    filterBy: 'FILTRAR POR',
    filteredBy: 'FILTRADO POR',
    page: 'PÁGINA',
    of: 'DE',
    legend: 'LEYENDA',
    preferences: 'PREFERENCIAS',
    print: 'IMPRIMIR',
    columns: 'COLUMNAS',
    rows: 'FILAS',
    next: 'SIGUIENTE',
    last: 'ÚLTIMO',
    first: 'PRIMERO',
    previous: 'ANTERIOR',
    selectFilter: 'Seleccione un Filtro',
    viewBy: 'VISTA POR',
    Mode: 'CONDICIÓN',
    shippingAgency: 'AGENTE NAVIERO',
    shippingLineName: 'LINEA',
    shippingLineRepresentative: 'LINEA REPRESENTANTE',
    errorsPleaseCheck: 'Existen errores, por favor, verifique.',
    containersList: 'LISTADO DE CONTENEDORES',
    containerGroup: 'GRUPO',
    containerCode: 'CÓDIGO DE CONTAINER',
    blPositioning: 'POSICIONAMIENTO',
    containerType: 'TIPO DE CONTENEDOR',
    codesPerDocuments: 'VER CODIGOS POR DOCUMENTOS',
    containersCodesPerDocuments: 'VER CODIGOS POR DOCUMENTOS',
    exportToExcel: 'EXPORTAR INFO A EXCEL',
    serialNumber: 'SIGLAS',
    damageType: 'TIPO DE DAÑO',
    damageTypes: 'TIPOS DE DAÑO',
    damagesType: 'TIPO DE DAÑOS',
    damageTypeRegistration: 'NUEVO TIPO DE DAÑO',
    damageTypeName: 'NOMBRE DE TIPO DE DAÑO',
    verifyFields: 'Verificar Campos',
    eir: 'EIR',
    eirList: 'LISTADO DE EIR',
    eirType: 'TIPO EIR',
    eirTypeName: 'NOMBRE DE TIPO EIR',
    eirTransaction: 'TRANSACCIÓN EIR',
    eirTypeTransaction: 'TIPO DE TRANSACCIÓN EIR',
    eirTypeTransactionName: 'NOMBRE DE TIPO DE TRANSACCIÓN EIR',
    cargoGroup: 'GRUPO CARGA',
    cargoGroupName: 'NOMBRE DE GRUPO CARGA',
    cargoGroupList: 'LISTADO DE GRUPO CARGA',
    listMeasurementUnits: 'VER UNIDADES DE MEDIDAS RELACIONADAS',
    measurementUnits: 'TIPOS DE UNIDAD',
    measurementUnit: 'TIPO DE UNIDAD',
    MeasurementUnit: 'UNIDAD DE MEDIDA',
    measurementUnitList: 'LISTA DE UNIDADES',
    unitsOfMeasurement: 'UNIDADES DE MEDIDA RELACIONADAS',
    cargoState: 'ESTADO',
    stateName: 'NOMBRE ESTADO',
    stateDescription: 'DESCRIPCIÓN DE ESTADO',
    packaging: 'EMBALAJE',
    packagings: 'EMBALAJES',
    packagingsSerial: 'EMBALAJES / SERIALES',
    packagingName: 'NOMBRE DE EMBALAJE',
    packagingRelationship: 'ASOCIAR EMBALAJE',
    associatePackaging: 'ASOCIAR EMBALAJE',
    commodity: 'MERCANCÍA',
    commodityName: 'NOMBRE DE LA MERCANCÍA',
    commodityDescription: 'DESCRIPCIÓN DE LA MERCANCÍA',
    commodityRelationship: 'ASOCIAR MERCANCÍA',
    commodityHandlingDocumentation: 'MANUALES DE MANIPULACIÓN DE LA MERCANCÍA',
    bulkCargoList: 'LISTADO DE CARGA GRANEL',
    bulkCargoState: 'ESTADO CARGA GRANEL',
    bulkCargoCommodity: 'MERCANCIA DE LA CARGA GRANEL',
    bulkCargoRelationship: 'ASOCIAR EMBALAJE CARGA GRANEL',
    newBulkCargoRegistration: 'REGISTRAR NUEVO ESTADO CARGA GRANEL',
    relationshipBulkCargoCommodity: 'ASOCIAR MERCANCIA DE CARGA GRANEL',
    applyToAll: 'APLICAR A TODOS',
    heading: 'RUBRO',
    generalCargoGroups: 'GRUPOS',
    generalCargoGroup: 'GRUPO CARGA GENERAL',
    generalCargoGroupClassification: 'CLASIFICACIÓN GRUPO CARGA GENERAL',
    generalCargoList: 'LISTADO DE CARGA GENERAL',
    generateTransaction: 'GENERAR TRANSACCIÓN ',
    associateGeneralGroupCargoPackaging: 'ASOCIAR EMBALAJE GRUPO CARGA GENERAL',
    unitType: 'TIPO DE UNIDAD',
    unitTypes: 'TIPOS DE UNIDAD',
    weightRange: 'RANGO DE PESO',
    weightLimit: 'LIMITE',
    lessOrEqualThan: 'MENOR O IGUAL',
    greaterOrEqualThan: 'MAYOR O IGUAL',
    manualsHandlingCommodity: 'MANUALES MANIPULACIÓN MERCANCIA',
    handlingManuals: 'MANUALES DE MANIPULACIÓN',
    statusName: 'NOMBRE ESTATUS',
    planificacionEstiba: 'PLANIFICACIÓN ESTIBA',
    generalData: 'DATOS GENERALES',
    dragAndDropToUploadContent: 'Arrastra y suelta para subir contenido',
    orClickToSelectFileFromYourComputer: 'o haga clic para seleccionar un archivo de su computadora',
    TotalHours: 'CANT HORAS',
    EstimatedHours: 'HORAS ESTIMADAS',
    craneWorkShifts: 'TURNOS DE TRABAJO DE GRUA',
    CraneWorkShift: 'TURNO DE GRUA',
    machineCondition: 'CONDICIÓN MAQUINA',
    machineSupplier: 'PROVEEDOR MAQUINA',
    machineType: 'TIPO DE MAQUINA',
    machineName: 'NOMBRE MAQUINA',
    inactivateCranePlanification: 'INACTIVAR PLANIFICACION DE GRUA',
    inactivateMachinePlanification: 'INACTIVAR PLANIFICACION DE MAQUINA',
    assignCraneWorkShift: 'ASIGNAR TURNO DE GRUA',
    assignMachineWorkShift: 'ASIGNAR TURNO DE MAQUINA',
    electronicData: 'ARCHIVOS ELECTRONICOS',
    liftingSpeed: 'VELOCIDAD DE SUBIDA',
    loweringSpeed: 'VELOCIDAD DE BAJADA',
    maxLoadCapacity: 'CAPACIDAD MAXIMA DE CARGA',
    onTheLine: 'EN LA LÍNEA',
    maximumRadio: 'RADIO MAX',
    loadDischargeOperations: 'OPERACIONES DE CARGA/DESCARGA',
    deleteFile: '¿Esta seguro de eliminar este archivo?',
    isoCode: 'CÓDIGO ISO',
    isoStandar: 'ESTANDAR ISO',
    Estandar: 'ESTANDAR',
    forAllTypeLoadsInGeneral: 'PARA TODO TIPO DE CARGA EN GENERAL',
    itsNotColour: 'NO ES UN COLOR',
    elementName: 'NOMBRE ELEMENTO',
    accesoryName: 'NOMBRE ACCESORIO',
    componentName: 'NOMBRE COMPONENTE',
    resume: 'RESUMEN',
    plans: 'PLANO DEL BUQUE',
    transacModalTitle: 'TRANSACCIONES GENERADAS POR',
    removeContainerOne: 'ESTA SEGURO DE ELIMINAR EL CONTENEDOR',
    removeContainerTwo: 'EN LA POSICION DEL BAY',
    deviceList: 'LISTADO DE DISPOSITIVOS',
    device: 'DISPOSITIVO',
    registerDevice: 'REGISTRAR NUEVO DISPOSITIVO',
    editDevice: 'EDITAR DISPOSITIVO',
    macAddress: 'DIRECCION MAC',
    deviceName: 'NOMBRE DISPOSITIVO',
    deviceType: 'TIPO DISPOSITIVO',
    operatingSystem: 'SISTEMA OPERATIVO',
    storageCapacity: 'MEMORIA INTERNA',
    screenSize: 'TAMAÑO PANTALLA',
    largo: 'LARGO',
    resolution: 'RESOLUCION',
    codImei: 'CODIGO IMEI',
    validateDevice: 'VALIDAR DISPOSITIVO',
    depth: 'ESPESOR',
    manufacturer: 'FABRICANTE',
    levelPreference: 'NIVEL PREFERENCIA',
    assignDevice: 'ASIGNACION DISPOSITIVOS',
    responsible: 'RESPONSABLE',
    transfer: 'TRANSFERIR',
    hatch: 'TAPA',
    reeferSockets: 'TOMAS REFRIGERADAS',
    socket: 'TOMA',
    totalQuantity: 'CANTIDAD TOTAL',
    vesselSheet: 'FICHA TECNICA BUQUE',
    bale: 'BALA',
    baleOnGrain: 'CAPACIDAD DE GRANO',
    baleOnDeck: 'CAPACIDAD EN CUBIERTA',
    stacking: 'APILADO',
    stack: 'PILA',
    upTier: 'ALTURA MAXIMA',
    row: 'FILA',
    inHold: 'BODEGA',
    onDeck2: 'SOBRE CUBIERTA',
    generalFilter: 'FILTRO GENERAL',
    multipleFilter: 'FILTRO MULTIPLE',
    matching: 'PAREO',
    executed: 'EJECUTADO',
    today: 'HOY',
    delays: 'RETRASOS',
    dateStartOperations: 'F. INICIO OPER.',
    dateEndOperations: 'F. ULTIMA OPERACION',
    endOperations: 'ULTIMA OPERACION',
    empty: 'VACIO',
    full: 'LLENO',
    clearFilters: 'LIMPIAR FILTROS',
    shipToShip: 'BUQUE A BUQUE',
    ship_ship: 'BUQUE-BUQUE',
    shipLandShip:'BUQUE TIERRA BUQUE',
    ship_land_ship: 'BUQUE-TIERRA-BUQUE',
    shipment: 'EMBARQUE',
    stowagePlanning: 'PLANIFICACION DE ESTIBA',
    stevedore: 'ESTIBADOR',
    cranesMovements: 'MOVIMIENTOS DE GRUA',
    averageMovementsPerHrs: 'PROMEDIO DE MOVIMIENTOS POR HORA',
    DischargeMovements: 'MOVIMIENTOS DE DESEMBARQUE',
    LoadMovements: 'MOVIMIENTOS DE EMBARQUE',
    HatchMovements: 'MOVIMIENTOS DE LA TAPA DE LA ESCOTILLA',
    MovsNumbers: 'NUMERO DE MOVIMIENTOS',
    vesselPlanning: 'PROGRAMACION DE BUQUES',
    mobilized:'MOVILIZADOS',
    Restow:'MOVILIZADOS',
    TimeLost: "TIEMPO PERDIDO",
    Scale: "BASCULA",
    nameScale: "NOMBRE BASCULA",
    connectedTo: 'CONECTADO A',
    connected: 'CONECTADO',
    readingFile: 'LEYENDO ARCHIVO',
    errosHourPleaseCheck: 'Error en horas, favor validar',
    errosDatePleaseCheck: 'Error en fechas, favor validar',
    applyPlan: 'APLICA PLANO',
    refresh: 'ACTUALIZAR',
    format: 'FORMATO',
    resourcesQty: 'CANTIDAD DE RECURSOS',
    rightRows: 'FILAS CORRECTAS',
    wrongRows: 'FILAS CON ERRORES',
    bankDetails: 'DATOS BANCARIOS',
    the: 'EL',
    successUpload: 'SE CARGO DE MANERA EXITOSA',
    errorsStaff: 'DESCARGUE EL ARCHIVO EXCEL CON LAS FILAS INCORRECTAS PARA SU CORRECCION',
    column: 'COLUMNA',
    deactivateFile: '¿Esta seguro de desactivar este archivo?',
    phone: 'TELÉFONO',
    bank: 'BANCO',
    currency: 'MONEDA',
    accountType: 'TIPO CUENTA',
    accountNumber: 'N° CUENTA',
    holder: 'TITULAR',
    alias: 'ALIAS',
    bankAccount: 'CUENTA BANCARIA',
    P2P: 'PAGO MOVIL',
    initialInventory: 'INVENTARIO INICIAL',
    inventory: 'INVENTARIO',
    commodityType: 'TIPO MERCANCÍA',
    certificationDate: 'FECHA CERTIFICACIÓN',
    clientRif: 'RIF CLIENTE',
    inventoryContains: 'DICE CONTENER',
    totalWeight: 'PESO TOTAL',
    sureNotSerializePackaging:'¿ESTÁS SEGURO DE NO SERIALIZAR EL EMBALAJE?',
    reportStaffList: 'REPORTE - LISTA DE CUADRILLA',
    netWeight: 'PESO NETO',
    manualLoad: 'CARGA MANUAL',
    historic: 'HISTORICO',
    reception: 'RECEPCIÓN',
    scrapReception: 'RECEPCION CHATARRA',
    entryOrders: 'ORDENES DE INGRESO',
    massiveLoading: 'CARGA MASIVA',
    newReception: 'NUEVA RECEPCION',
    editReception: 'EDITAR RECEPCION',
    nroControl: 'Nº CONTROL',
    idDriver: 'CI CHOFER',
    nroGuide: 'Nº GUIA',
    carPlate: 'PLACA VEHICULO',
    carrierCompany: 'EMPRESA TRANSPORTE',
    carrierRif: 'RIF TRANSPORTE',
    receptionDate: 'FECHA RECEPCIÓN',
    truckScale: 'BASCULA',
    typeMovement: 'TIPO MOVIMIENTO',
    metalScrapReception: "LISTA DE RECEPCIÓN CHATARRA",
    listReception: "LISTA DE RECEPCIÓN",
    scrapReception: "RECEPCIÓN CHATARRA",
    circuit: 'CIRCUITO',
    circuits: 'CIRCUITOS',
    circuitList: 'LISTADO DE CIRCUITOS',
    circuitName: 'NOMBRE DE CIRCUITO',
    circuitNameLabelEn: 'NOMBRE CIRCUITO EN',
    circuitNameLabelEs: 'NOMBRE CIRCUITO ES',
    mandatory: 'REQUERIDO',
    require: 'REQUIERE',
    radioactivity: 'RADIOACTIVIDAD',
    radioactivityLevel: 'NIVEL DE RADIOACTIVIDAD',
    machineryInYard: 'MAQUINARIA EN PATIO',
    movementsInYard: 'MOVIMIENTOS EN PATIO',
    selectRequireOption: 'SE REQUIERE AL MENOS 1 OPCION SELECCIONADA',
    selectDateOption: 'OPCION DE FECHA REQUERIDA',
    entryDate: 'FECHA ENTRADA',
    outDate: 'FECHA SALIDA',
    consignee: 'CONSIGNATARIO',
    declaredConsignee: "CONSIGNATARIO DECLARADO",
    commodity: 'MERCANCIA',
    stowagePlan: 'PLAN DE ESTIBA',
    importer: 'IMPORTADOR',
    exporter: 'EXPORTADOR',
    rifExporter: 'RIF EXPORTADOR',
    rifImporter: 'RIF IMPORTADOR',
    rifConsignee: 'RIF CONSIGNATARIO',
    notifyParty: 'NOTIFICAR A',
    seller: 'VENDEDOR',
    cargoType: 'TIPO DE CARGA',
    viscosity: 'VISCOSIDAD',
    temperature: 'TEMPERATURA',
    estimatedToShip: 'ESTIMADO A EMBARCAR',
    actualInventory: 'INVENTARIO ACTUAL',
    available: 'DISPONIBLE',
    occupied: 'OCUPADO',
    assignationPlanned: 'ASIGNACIÓN PLANIFICADA',
    assignBl: 'ASIGNAR BL',
    assignTon: 'ASIGNAR TON',
    tonAssigned: 'TON ASIGNADAS',
    clientExporter: 'CLIENTE EXPORTADOR',
    clientImporter: 'CLIENTE IMPORTADOR',
    billOfLading: 'MANIFIESTO DE CARGA',
    plannedAssignment: 'ASIGNACIÓN PLANIFICADA',
    declared: 'DECLARADO',
    assignation: 'ASIGNACIÓN',
    loadCondition: 'CARGA - CONDICIONES',
    availabilityByBl: 'DISPONIBILIDAD POR BL',
    equipment: 'EQUIPO',
    planned: 'PLANIFICADOS',
    loadPortSituation: 'CARGA',
    executedPortSituation: 'EJECUTADO',
    BTB: 'BB',
    twinBoxes: 'CAJAS PIÑAS',
    ultMov: 'ULT MOV',
    lastRead: 'ULT LECTURAS',
    availability: 'DISPONIBILIDAD',
    noDelays: "NO HAY RETRASOS",
    onCircuit: 'EN CIRCUITO',
    tonLastRead: 'TON ULT LECTURA',
    otherTerminals: 'OTRAS TERMINALES',
    plannedEquipment: 'EQUIPO PLANIFICADO',
    iniOps: 'INI. (OPS)',
    puestoDeAtraque: 'PUESTOS DE ATRAQUE',
    needFilter: 'DEBE SELECCIONAR AL MENOS UNA OPCION DEL FILTRO',
    needOptionCol: 'DEBE SELECCIONAR LA COLUMNA PARA GENERAR EL PAREO',
    needOptionRow: 'DEBE SELECCIONAR LA FILA PARA GENERAR EL PAREO',
    reverses: 'REVERSOS',
    pendings: 'PENDIENTES',
    freePositions: 'LIBERAR POSICIONES',
    currentAction: 'ACCION ACTUAL',
    showAll: 'MOSTRAR TODO',
    invalidOptionPort: 'OPCIÓN NO VÁLIDA PARA ESTE PUERTO',
    invalidPosition: 'POSICIÓN NO VÁLIDA',
    help:'AYUDA',
    restowPortSituation: 'REPOSICIONAMIENTO',
    abrevSLS: 'B/T/B',
    deleteBay: '¿Esta seguro de eliminar este bay?',
    equipmentCancel: 'CANCELACION DE EQUIPOS',
    mustSelectMovType: 'DEBE SELECCIONAR UN TIPO DE MOVIMIENTO',
    mustSelectContainer: 'DEBE SELECCIONAR POR LO MENOS UN CONTENEDOR',
    listOfOnBoardContainers: 'LISTA DE CONTENEDORES A BORDO',
    restowType:'TIPO DE RESTOW',
    newPosition: 'POSICION NUEVA',
    CraneErrorTransversalView: 'ERROR DE GRÚA EN VISTA TRANSVERSAL',
    vesselPlan: 'PLAN DEL BUQUE',
    yardDetails: 'DETALLES DEL PATIO',
    yardsGeneralList: 'LISTADO GENERAL DE PATIOS',
    capStatic: 'CAP. ESTATICA',
    capOperative: 'CAPACIDAD OPERATIVA',
    adminOffice: 'OFICINA ADMIN',
    adminsOffices: 'OFICINAS ADMINISTRATIVAS',
    nroAccess: 'NRO ACCESOS',
    yardType: 'TIPO DE PATIO',
    subclassification: 'SUBCLASIFICACIÓN',
    blocksBays: 'BLOQUES - BAYS',
    access: 'ACCESOS',
    yardPlan: 'PLANO DEL PATIO',
    condition: 'CONDICIÓN ',
    accessType: 'TIPO DE ACCESO',
    vesselPlan: 'PLANO DEL BUQUE',
    yardsList: 'LISTADO DE PATIOS',
    yardType: 'TIPO DE PATIO',
    occupiedTeus: 'TEUS OCUPADOS',
    availableTeus: 'TEUS DISPONIBLES',
    inspectionLocation: 'LUGAR DE INSPECCIÓN',
    bayInspection: 'BAHIA',
    vesselsServedGeneralList: 'LISTADO GENERAL DE BUQUES ATENDIDOS',
    mobilizedTon: 'TONELADAS MOVILIZADAS',
    visitsByVessel: 'LISTA DE VISITAS POR BUQUE', 
    visitList: 'LISTA DE VISITA', 
    beginningOperationDate: 'FECHA INICIO OPERACIONES', 
    endOperationDate: 'FECHA FIN OPERACIONES',
    comercialAllianceWith: 'ALIANZA ESTRATEGICA COMERCIAL CON',
    bill: 'FACTURA',
    billingDate: 'FECHA FACTURACION',
    taxBase: 'BASE IMPOSIBLE',
    quantityOfMovilizedContainers: 'CANTIDAD DE CONTENEDORES MOVILIZADOS',
    toBeFilled: 'PARA SER LLENADO EN ADMON CENTRAL',
    by: 'POR',
    billing: 'FACURACION',
    allianceParticipation: 'PARTICIPACION ALIANZA',
    approvedBy: 'APROBADO POR',
    yardSetting: 'CONFIGURACION DE PATIO',
    selectedYardSettingList: 'LISTA DE CONFIGURACION DE PATIO SELECCIONADO',
    approveSetting: 'APROBAR CONFIGURACION',
    reverseSetting: 'REVERSAR CONFIGURACION',
    blocks: 'BLOQUES',
    cantBlocks: 'CANT DE BLOQUES',
    cantAreas: 'CANT DE AREAS',
    heights: 'ALTURA',
    yardGroup: 'GRUPO DE PATIO',
    office: 'OFICINA',
    recognition: 'RECONOCIMIENTO',
    workshop: 'TALLER',
    electricalSubstation: 'SUBESTACION ELECTRICA',
    electricalTowers: 'TORRES ELECTRICAS',
    operationalCapacity: 'CAPACiDAD OPERATIVA',
    blockWarn: 'EL BLOQUE NO PUEDE SER ASIGNADO A LA ZONA',
    yardContainerGeneralInfo: 'INFORMACIÓN GENERAL DEL CONTENEDOR EN PATIO',
    daysInYard: 'DÍAS EN PATIO',
    mainFeatures: 'PRINCIPALES CARACTERISTICAS',
    viewByLevel: 'VISTA POR NIVEL',
    planView: 'VISTA DE PLANTA',
    noContainerFound: 'EQUIPO NO SE ENCUENTRA EN PATIO',
    disableConfig: '¿Esta seguro de desactivar esta configuración?',
    invalidDimensions: 'DIMENSIONES NO VALIDAS PARA EL AREA',
    //DANIEL
    //-----------------------------------------------------------------------------------------------------------
    inventoriesOfContainersInThePort:'INVENTARIOS DE CONTENEDORES EN EL PUERTO',
    TotalMovements: "TOTAL MOVIMIENTOS",
    Day:'DÍA',
    OperationSituationCurrent:"SITUACIÓN OPERATIVA ACTUAL",
    OperationSituationHistorical:"SITUACIÓN OPERATIVA HISTÓRICA",
    QuantityContainers:'CANTIDAD DE CONTENEDORES',
    MobilizedContainers:'CONTENEDORES MOVILIZADOS',
    CranesTimeLine: 'LÍNEA DE TIEMPO DE GRÚAS',
    TheValueIs:"El valor es: ",
    Minimize:"Minimizar",
    Maximize:"Maximizar",
    OperationSituationOptions:{
      OperationalSituation:"SITUACIÓN OPERATIVA",
      InactivityTime:"TIEMPOS DE INACTIVIDAD",
      HistoryVesselsServed:"HISTORICO DE BUQUES ATENDIDOS",
      Productivity:"PRODUCTIVIDAD",
      ProductivityTime:"PRODUCTIVIDAD POR CORTE",
    },
    InactivityTimeOptions:{
      InactivityTimePerCrane:"TIEMPOS DE INACTIVIDAD POR GRUA",
      InactivityTimeTheVessel:"TIEMPOS DE INACTIVIDAD POR TODO EL BUQUE",
      MovementsPerHoursVsNetHours:"MOVIMIENTOS POR HORA VS HORAS NETA",
      MovementsPerHour: "MOVIMIENTOS POR HORA",
    },
    Lines:"LÍNEAS",
    TypeOfContainer:"TIPOS DE CONTENEDOR",
    Movements:"MOVIMIENTOS",
    sumaries:"SUMARIOS",
    ExportAs:"EXPORTAR COMO: ", 
    Month:"MES",
    Yearh: "AÑO",
    selectedYear:"SELECCIONE AÑO...",
    selectedMonth:"SELECCIONE MES...",
    Description:"DESCRIPCIÓN",
    lists:"LISTAS",
    currentStatusVessel:"SITUACIÓN ACTUAL DEL BUQUE",
    preliminarycuts:"CORTES PRELIMINARES",
    reportQuery:"CONSULTA DE REPORTE",
    dateRequired:"LA FECHA ES REQUERIDA EN ",
    dateInvalidIn:"FECHA INVÁLIDA EN ",
    nameRequiredIn:"CAMPO NOMBRE REQUERIDO EN ",
    dateInvalidHigherIn:"FECHA NO PUEDE SER MAYOR A ",
    dateInvalidLessIn:"FECHA NO PUEDE SER MENOR A ",
    ammounInvalidZero:"LA CANTIDAD NO PUEDE SER MENOR A CERO ",
    In:" EN ",
    invalidDatesList:{
      titleDate:"La fecha de ",
      invalidlessThan: 'No puede ser menor que ',
      invalidgreaterThan: 'No puede ser mayor que ',
      invalidlessThanOrEqual: 'Debe ser menor o igual que ',
      invalidgreaterThanOrEqual: 'Debe ser mayor o igual que ',
      invalidOrEqual: 'No puede ser igual que ',
    },
    doubleClickEdit: "doble click para editar",
    statusVisit: "ESTATUS DE LA VISITA",
    benningOperation: "INICIO DE OPERACIONES",
    endOperation: "FIN DE OPERACIONES",
    transactionHistory: "HISTORIAL DE TRANSACCIONES",
    shippingLine: "COMPAÑÍA NAVIERA",
    shippingAgent: "AGENTE NAVIERO",
    shippingLineVoyage: "VIAJE LÍNEA NAVIERA ",
    portOperator: "OPERADOR PORTUARIO",
    berth: "MUELLE",
    portOrigin: "PUERTO PROCEDENCIA",
    portDestiny: "PUERTO DESTINO",
    field: "CAMPO",
    agenda: "AGENDA",
    times: "TIEMPOS",
    summary_of_operations: "RESUMEN DE OPERACIONES",
    activityType: 'TIPO DE ACTIVIDAD',
    ock_file: "OCK EXPEDIENTE",
    menu_diary: {
      TERMINAL_DETAILS: "DETALLES DE TERMINAL",
      AGENDA_DETAILS: "DETALLES DE LA AGENDA",
      CRANES: "GRÚAS",
    },
    list_terminal: {
      n_toque: 'TOQUE',
      viaje_arribo: 'VIAJE ARRIBO',
      viaje_zarpe: 'VIAJE ZARPE',
    },
    ships_captain: 'CAPITÁN DEL BUQUE',
    ship_security_level: 'NIVEL DE PROTECCIÓN DEL BUQUE',
    name_pilot: 'NOMBRE DEL PILOTO',
    activitys_list: {
      call_time: 'TIEMPO DE LLAMADA',
      dock_pilot: 'PILOTO DE ATRAQUE',
      appointment_crew: 'NOMBRAMIENTO DE CUADRILLA',
    },
    filters: "FILTROS",
    selectAll: 'SELECCIONAR TODO',
    preliminary_file: "archivo preliminar",
    PreliminaryDischargeFile: "ARCHIVO DE DESCARGA PRELIMINAR",
    PreliminaryShipmentFile: "ARCHIVO DE EMBARQUE PRELIMINAR",
    TITLE_PRELIMINAR_FILE: 'TÍTULO ARCHIVO PRELIMINAR',
    AUTHORIZED_BY: 'AUTORIZADO POR',
    ARRIVAL_POSITION: 'POSICIÓN DE LLEGADA',
    ARRIVAL_POSITION_DATE: "POSICIÓN DE LLEGADA FECHA",
    ARRIVAL_POSITION_TIME: "HORA DE LLEGADA",
    DEPARTURE_POSITION: 'POSICIÓN DE SALIDA',
    DEPARTURE_POSITION_DATE: "POSICIÓN DE SALIDA FECHA",
    DEPARTURE_POSITION_TIME: "HORA DE SALIDA",
    VOYAGE_ARRIVAL: "VIAJE DE ARRIBO",
    VOYAGE_DEPARTURE: "VIAJE DE ZARPE",
    MOVIMIENTO: {
      SHIP_TO_SHIP: "MOVIMIENTO BUQUE - BUQUE",
      SHIP_LAND_TO_SHIP: "MOVIMIENTO BUQUE TIERRA BUQUE",
      CANCEL: "CANCELADO",
      observation: "OBSERVACIONES"
    },
    anular_movimiento_restow: '¿Esta seguro de anular el movimiento restow?',
    filter_preliminar: {
      see_booth: "VER AMBAS",
      see_discrepancies: "VER DISCREPANCIAS",
      see_matches: "VER COINCIDENCIAS",
    },
    container_data: {
      not_siglas: "SIGLAS DEL CONTENEDOR NO CUMPLEN EL FORMATO DE VALIDACIÓN",
      notsameAsLoading: "Puerto de descarga no puede ser igual al de carga",
      notsameAsDischarge: "Puerto de carga no puede ser igual al de descarga",
      container_size: "El contenedor no puede superar el tamaño máximo de ",
      container_weight: "El contenedor no puede superar el peso máximo de ",
    },
    aplicar_restow: 'APLICAR / EDITAR MOVIMIENTO RESTOW',
    differences: "DIFERENCIAS",
    filename: 'NOMBRE DE ARCHIVO',
    auxiliary_file: "ARCHIVO AUXILIAR",
    decription: "DESCRIPCIÓN",
    value: "VALOR",
    bug_list: "LISTA DE ERRORES",
    download_file_structure: 'Descargar estructura del archivo',
    download_instructive: 'DESCARGAR INSTRUCTIVO',
    download_auxiliar_list_instructions: 'DESCARGAR INSTRUCTIVO LISTA AUXILIARES',
    visits: "VISITAS",
    visitItineraty: "VISITA / ITINERARIO",
    Timeslost: "TIEMPOS PERDIDOS",
    detailsmobilizedteams: "DETALLES EQUIPOS MOVILIZADOS",
    cranesTypes: "TIPO DE GRÚA",
    deviceCode: "CODIGO DISPOSITIVO",
    duration: "DURACIÓN",
    timeLine: "LÍNEA DE TIEMPO",
    noDataAvailable: 'No hay datos disponibles',
    totalMovemnsPerDay: 'TOTAL MOVIMIENTOS POR DÍA - CORTE',
    seeDatails:"VER DETALLES",
    LastUpdate: "Última actualización: ",
    NoLoadDistributionPlan: "No se dispone plano de distribución de carga para la visita seleccionada",
    NoHasOperations: "NO HA COMENZADO OPERACIONES",
    DirectDischarge: "DESCARGA DIRECTA",
    Seal:"PRECINTO",
    Seals:"PRECINTOS",
    Restow:"MOVILIZADOS",
    Warning:'ADVERTENCIA',
    Default: 'PREDETERMINADO',
    fileBaplie: "ARCHIVO BAPLIE",
    selectFile: "SELECCIONAR ARCHIVO",
    listings: "LISTADOS",
    preliminarList: "LISTA PRELIMINAR",
    selectedFiles: "ARCHIVOS SELECCIONADOS",
    resumido: "RESUMIDO",
    confirmedContainers: "CONTENEDORES CONFIRMADOS",
    filesTransferred: "TRANSFERENCIA DE ARCHIVOS",
    transferredFiles: "ARCHIVOS TRANSFERIDOS",
    totalContainers: "TOTAL DE CONTENEDORES",
    reverse:"REVERTIR",
    confirmedCOntainersDetails: "DETALLES DE CONTENEDORES CONFIRMADOS",
    reverseFile: '¿Está seguro de reversar la transferencia del archivo?',
    confirmationDate: 'FECHA CONFIRMACIÓN',
    confirmationDateAbrev: 'F. CONFIRMACIÓN',
    selectFilesElectronics: 'SELECCIONAR ARCHIVOS ELECTRÓNICOS',
    NextPort: 'PUERTO PRÓXIMO',
    DeliveryPort: 'PUERTO DE ENTREGA',
    Origin: 'PROCEDENCIA',
    assignResource: 'ASIGNACIÓN RECURSO',
    generalList: 'LISTADO GENERAL',
    accesoriesList: 'LISTADO DE ACCESORIOS',
    apply:"APLICA",
    highOutside: "ALTO EXTERIOR",
    lengthOutside: "LARGO EXTERIOR",
    widthOutside: "ANCHO EXTERIOR",
    volumeOutside: "VOLUMEN EXTERIOR",
    highInside: "ALTO INTERIOR",
    lengthInside: "LARGO INTERIOR",
    widthInside: "ANCHO INTERIOR",
    volumeInside: "VOLUMEN INTERIOR",
    emptyWeith: "PESO VACÍO",
    maximumLoad: "CARGA MAXIMA",
    maximunGrossWeight: "PESO BRUTO MÁXIMO",
    containerLists:{
      accesoriesList: 'LISTADO DE ACCESORIOS DE CONTENEDORES',
      clasificationsList:'LISTADO DE CLASIFICACIONES DE CONTENEDORES',
      isoCodesList: 'LISTADO DE CODIGOS ISO DE CONTENEDORES',
      componentsList: 'LISTADO DE COMPONENTES DE CONTENEDORES',
      conditionsList: 'LISTADO DE CONDICIONES DE CONTENEDORES',
      elementsList: 'LISTADO DE ELEMENTOS DE CONTENEDORES',
      groupsLists: 'LISTADO DE GRUPOS DE CONTENEDORES',
      sizeLists: 'LISTADO DE TAMAÑOS DE CONTENEDORES',
      statusLists: 'LISTADO DE ESTATUS DE CONTENEDORES',
      viewsLists: 'LISTADO DE VISTAS DE CONTENEDORES',
    },
    listsManual: 'LISTAS MANUALES',
    history: 'HISTÓRICO',
    typeOfFile: 'TIPO DE ARCHIVO',
    DownloadFormat: 'DESCARGAR FORMATO',
    Discrepancies: 'DISCREPANCIAS',
    UploadFile: 'SUBIR ARCHIVO',
    CustomListsFormat: 'FORMATO PARA LISTA DE SOBORDO',
    customBroker:'AGENTE ADUANAL',
    logistics:'LOGÍSTICA',
    NewTransportationCompany:'NUEVA EMPRESA TRANSPORTE',
    ScaleWeightRequired: 'REQUIERE EL USO DE BALANZA',  
    AssignCrane:'ASIGNAR GRÚA',
    stowageGans: 'CUADRILLA DE ESTIBA',
    fullName:'NOMBRE COMPLETO',
    deletedRecords: 'ELIMINAR REGISTROS',
    downloadExcel:'DESCARGAR EXCEL',
    openErrors: 'VER ERRORES',
    listOfDriversTransports:'LISTA DE CONDUCTORES POR TRANSPORTE',
    listOfVehiclesTransports:'LISTA DE VEHÍCULOS POR TRANSPORTE',
    reportMigrationMassive:'REPORTE MIGRACIÓN DE ARCHIVO',
    RequiredWeight:'REQUIERE PESAJE',
    SituacionBuque:'SITUACIÓN BUQUE',
    SituacionBodega:'SITUACIÓN BODEGA',
    Loadeds: 'EMBARCADAS',
    Returned: 'DEVUELTAS',
    Dispached: 'DESPACHADAS',
    VinCircuit: 'V. EN CIRCUITO',
    CTotalVoyages: 'C. TOTAL VIAJES',
    TonPlanning: 'TON. PLANIF',
    TonRemai: 'TON. RESTANTE',
    LastReading: 'ULTIMA LECTURA',
    DateLastRead: 'FECHA ULT LECTURA',
    CargoShipped: 'CARGA EMBARCADA',
    GamelaByWinery: 'GAMELA POR BODEGA',
    TonEmbargoedByShifts: 'TON EMBARCADAS POR TURNOS',
    EstimatedTONperScrap: 'TON. ESTIM GAMELA', 
    Diurnal:'DIURNO',
    Nocturnal:'NOCTURNO',
    transportationCarrier:'TRANSPORTE',
    StartLoard: 'INICIO DE CARGA',
    Remaing: 'RESTANTE',
    LoadEnd: 'FIN CARGA',
    done:'REALIZADO',
    CurrentSituationBlVessel: 'SITUACIÓN ACTUAL BODEGA-BL',
    ShippedEstimatedVsPlanned: 'PLANIFICADO VS EMBARCADO ESTIMADO',
    PlannedVsCertificate: 'PLANIFICADO VS CERTIFICADO',
    ToDischarge: 'POR EMBARCAR',
    Heavy:'PESADO',
    ValuesExpressedInMt: 'VALORES EXPRESADOS EN TON',
    EstimedTonShipped: 'TON ESTIMADO EMBARCADO',
    CurrentSituationCrane:'SITUACIÓN ACTUAL POR GRÚA',
    CurrentSituationTransportation:'SITUACIÓN ACTUAL TRANSPORTE',
    HatchMovilized:"TAPAS MOVILIZADAS",
    Certificate:'CERTIFICADO',
    CurrentSituationHold: 'SITUACIÓN ACTUAL BODEGA',
    IniOps: 'INI. (OPS)',
    FinOps: 'FIN. (OPS)',
    HrOperando: 'HR-OPERANDO',
    PerHours: 'X HORA',
    PerGamela: 'X GAMELA',
    TotalGamelas: 'TOTAL GAMELAS',
    Loaded: 'EMBARCADO',
    GamelaXdia:'GAMELA X DÍA',
    Gamela: 'GAMELA',
    PlannedVsCurrentStatus: 'PLANIFICADO VS SITUACIÓN ACTUAL',
    GamelaxHora: 'GAMELA X HORA',
    PerHour:'X HORA',
    HorasTrabajadas:'HRS. TRABAJADAS',
    HorasRetrasos:'HRS. RETRASOS',
    HorasEfectivas:'HRS. EFECTIVAS',
    Annulled:'ANULADAS',
    InCircuit: 'EN CIRCUITO',
    tonEstim: 'TON ESTIM',
    tonPlannif: 'TON PLANIF',
    listApk: 'LISTADO DE APK',
    nameApk: 'NOMBRE DE APK',
    typeApk: 'TIPO DE APK',
    creationDate: 'FECHA DE CREACIÓN',
    LastUpdateDate: 'FECHA ÚLTIMA ACTUALIZACIÓN',
    assignationGamela: 'ASIGNACIÓN DE GAMELA',
    HistoryAssociation:"HISTORICO DE ASOCIACIÓN",
    HistoryOrders:"HISTORICO DE ORDENES",
    D_emission: 'F. EMISION',
    D_shipping: 'F. EMBARQUE',
    DetenerOperaciones: 'DETENER OPERACIONES',
    AsignarCuadrilla: 'ASIGNAR CUADRILLA',
    Associateamela:'ASOCIAR GAMELA',
    AssociationGamela:'ASOCIACIÓN DE GAMELA',
    PorGamela: 'POR GAMELA',
    TotalLoaded: 'TOTAL EMBARCADO',
    TotalDelaysPerDay: 'TOTAL RETRASOS AL DÍA',
    TotalDelaysAccumulated: 'TOTAL RETRASOS ACUMULADOS',
    StopOperations: 'OPERACIONES DETENIDAS',
    StopOperations2: 'DETENER OPERACIONES',
    TonxHour:'TON X HORA',
    Ship:'BUQUE',
    OfOperation: 'DE OPERACIÓN',
    DayOfOperations: 'DÍAS DE OPERACIONES',
    PerDay:'X DÍA',
    LoadedTon: 'TON EMBARCADAS',
    TotalPerDay:'TOTAL POR DÍA',
    tonRestante: 'TON RESTANTES',
    StoppedOperation: 'OPERACIONES DETENIDAS',
    clientYard: 'CLIENTE - PATIO/ALMACÉN',
    qtyWeighed: 'CANT PESADA',
    tonHeavy: 'TON PESADO',
    qtyWithoutWeighing: 'CANT SIN PESAJE',
    tonEstimated: 'TON ESTIMADA',
    totalVoyageDone: 'TOTAL VIAJES REALIZADOS',
    StatusOperation: 'ESTATUS DE OPERACIONES',
    searchSuccesful: 'BÚSQUEDA EXITOSA',
    //HILGER
    attribute: 'ATRIBUTO',
    correctionControl: 'CONTROL DE CORRECCION',
    projection: 'PROYECCIÓN',
    exe: 'EJECUCION',
    workHour: 'HORAS DE TRABAJO',
    hour: 'HORA',
    estimated: 'ESTIMADO',
    average: 'PROMEDIO',
    equipmentDetail: 'DETALLE DE EQUIPO',
    loadPort: 'PUERTO DE CARGA',
    dischargePort: 'PUERTO DE DESCARGA',
    generalInfo: 'INFORMACIÓN GENERAL',
    recordInfo: 'HISTORIAL DE TRANSACCIONES',
    arrival: 'ARRIBO',
    departure: 'ZARPE',
    canceledList: 'LISTA DE MOVIMIENTOS CANCELADOS PLANIFICADOS',
    dischargeList: 'LISTA DE EQUIPOS A DESEMBARCAR',
    generate: 'GENERAR ',
    bapliesList: 'LISTADO DE BAPLIES GENERADOS',
    search: 'BUSCAR',
    searchContainer: 'BUSCAR CONTENEDOR',
    docked: 'ATRACADO',
    planned: 'PLANIFICADO',
    dockAssignment: 'ASIGNACIÓN DE MUELLE',
    asignDevice: 'ASIGNACIÓN DE DISPOSITIVOS',
    equipmentQuantity: 'CANTIDAD DE EQUIPOS',
    estimatedTime: 'TIEMPO ESTIMADO',
    deviceTransfer: 'TRANSFERIR DISPOSITIVO',
    selectEdi: 'SELECCIÓN DE EDI',
    confirmation: 'CONFIRMACIÓN',
    confirmed: 'CONFIRMADOS',
    tobeconfirmed: 'PENDIENTES POR CONFIRMAR',
    standardOrganization: 'ENTE REGULADOR',
    stowageStaff: 'CUADRILLA DE ESTIBA',
    mobilizedReport: 'REPORTE DE MOVILIZACION DE EQUIPOS',
    historyStaff: 'HISTÓRICO DE STAFF',
    companyStaff: 'STAFF POR EMPRESA',
    fileName: 'NOMBRE DE ARCHIVO',
    birthday: 'FECHA DE NACIMIENTO',
    charge: 'CARGO',
    inspection: 'INSPECCIÓN',
    inspections: 'INSPECCIONES',
    inspectionType: 'TIPO DE INSPECCIÓN',
    dimensions: 'DIMENSIONES',
    validateUser:'VALIDAR USUARIO',
    validateCode: 'VALIDAR CODIGO',
    moment: 'MOMENTO',
    transactionDate: 'FECHA TRANSACCIÓN',
    damage: 'DAÑO',
    newDamage: 'NUEVO DAÑO',
    editDamage: 'EDITAR DAÑO',
    inspectionMoment: 'MOMENTO INSPECCION',
    inspectionSeals: 'INSPECCION DE PRECINTOS',
    inspectionDamage: 'INSPECCION DE DAÑOS',
    received: 'RECIBIDO',
    loadGroupAssociatedTo: 'GRUPO DE CARGA ASOCIADO A',
    container: 'CONTENEDOR',
    width: 'ANCHO',
    weightEmpty: 'PESO VACIO',
    MaxWeight: 'PESO MAXIMO',
    maxLoad: 'CARGA MAXIMA',
    maxGrossWeight: 'MAXIMO PESO BRUTO',
    //jose angel
    itemsAndCommoditiesList: 'LISTADO DE RUBROS Y MERCANCIAS',
    pdfManuals: 'MANUALES',
    uploadCommodityDocuments: 'SUBIR DOCUMENTOS',
    handlingDocumentation: 'MANUAL DE MERCANCIA',
    openDocumentation: 'VER MANUAL',
    uploadHandlingDocumentation: 'CARGAR MANUALES DE MERCANCIA',
    listOfGroupsAndImplements: 'LISTA DE GRUPOS E IMPLEMENTOS',
    listOfImplements: 'LISTADO DE IMPLEMENTOS',
    Implement: 'IMPLEMENTO',
    implementsPerPage: ' IMPLEMENTOS POR PAGINA',
    implementsGroup: 'GRUPO IMPLEMENTO',
    groups: 'GRUPOS',
    implements: 'IMPLEMENTOS',
    implement: 'IMPLEMENTO',
    gruopName: 'NOMBRE DE GRUPO',
    implementName: 'NOMBRE DE IMPLEMENTO',
    implementDescription: 'DESCRIPCION DE IMPLEMENTO',
    unitConvertionList: 'LISTADO DE CONVERSION DE UNIDADES',
    newConvertionUnit: 'NUEVA REGLA DE CONVERSIÓN',
    unit: 'UNIDAD',
    equals: 'EQUIVALE',
    operator: 'OPERADOR',
    units: 'UNIDADES',
    destination: 'DESTINO',
    convertionUnitRegistration: 'REGISTRAR REGLA DE CONVERSION',
    editConversionRule: 'EDITAR REGLA DE CONVERSION',
    convertionunitType: 'TIPO DE UNIDAD DE ORIGEN',
    typeOfUnitToConvert: 'TIPO DE UNIDAD A CONVERTIR',
    unitOfOrigin: 'UNIDAD DE ORIGEN',
    unitOfDestination: 'UNIDAD DE DESTINO',
    mathematicalOperation: 'OPERACIÓN',
    packagingList: 'LISTADO DE EMBALAJES',
    groupAndType: 'GRUPO Y TIPO',
    imdg: 'IMDG',
    imdgClass: 'CLASE IMDG',
    newImdg: 'NUEVO IMDG',
    imdgCode: 'CODIGO IMDG',
    imdgDivision: 'DIVISION IMDG',
    classCode: 'CODIGO CLASE',
    imdgList: 'LISTADO DE IMDG',
    imdgClassName: 'NOMBRE DE CLASE IMDG',
    imdgClassAcronym:'SIGLA DE CLASE IMDG',
    classAcronym: 'SIGLA CLASE',
    imdgDivisionName: 'NOMBRE DE DIVISION IMDG',
    measurementUnitsList: 'LISTADO DE UNIDADES DE MEDIDA',
    measurementUnitType: 'TIPO UNIDAD MEDIDA',
    meaurenmentUnitRegistration: 'REGISTRAR UNIDAD DE MEDIDA',
    measurementUnitAcronym: 'SIGLAS DE UNIDAD DE MEDIDA',
    measurementUnitName: 'NOMBRE DE UNIDAD DE MEDIDA',
    measurementUnitTypeName: 'NOMBRE DE TIPO UNIDAD DE MEDIDA',
    radioactiveMaterials: 'MATERIALES RADIOACTIVOS',
    multiplication:'MULTIPLICACION',
    editImplementGroup:'EDITAR GRUPO IMPLEMENTO',
    conversionRule:'REGLA DE CONVERSIÓN',
    portActivityName:'NOMBRE ACTIVIDAD PORTUARIA',
    municipalityName:'NOMBRE DEL MUNICIPIO',
    countryName:'NOMBRE PAIS',
    alpha2Code:'CODIGO ALPHA 2',
    alpha3Code:'CODIGO ALPHA 3',
    countryNumericCode:'CODIGO NUMERICO',
    flag:'BANDERA',
    parishName:'NOMBRE DE LA PARROQUIA',
    everybody:'TODOS',
    moduleGroup:'GRUPO MODULO',
    viewHierarchy:'VER JERARQUIA',
    seeRolesAssociatedWithTheModule:'VER ROLES ASOCIADOS AL MODULO',
    dashLogin:'DASH INICIO SESIÓN',
    groupModuleList:'LISTADO DE GRUPO MODULO',
    father:'PADRE',
    newModuloRegistrarion:'REGISTRAR GRUPO MODULO',  
    dependencies:'DEPENDENCIAS',
    gpmoduloName:'NOMBRE GPMODULO',
    apiRoutes:'RUTAS API',
    structure:'ESTRUCTURA',
    fileDirectory:'DIRECTORIO DE ARCHIVOS',
    loadBaplie: 'BAPLIE DE CARGA',
    dischargeBaplie: 'BAPLIE DE DESCARGA',  
    //Nubia
    grossProductivityWithLostTime: "'GROSS' PRODUCTIVIDAD CON LOS TIEMPOS PERDIDOS",
    berthProductivityWithLostTime: "'BERTH' PRODUCTIVIDAD CON LOS TIEMPOS PERDIDOS",
    netProductivityWithoutLostTime: "'NET' PRODUCTIVIDAD SIN LOS TIEMPOS PERDIDOS",
    beginningDischarge: 'INICIO DESCARGA',
    finishDischarge: 'FINAL DESCARGA',
    beginningLoad: 'INICIO EMBARQUES',
    finishLoad: 'FINAL OPERACIONES',
    twinsBoxes: 'CAJAS PIÑA',
    hatchCovers: 'TAPAS',
    humanResources: 'RRHH',
    forklift: 'MONTACARGAS',
    liftingEquipment: 'EQUIPOS IZAMIENTOS',
    security: 'SEGURIDAD',
    wincher: 'WINCHEROS',
    receivingWarehouse: 'ALMACÉN RECEPTOR',
    totalTimeOfOperations: 'TIEMPO TOTAL DE OPERACIONES',
    netTimeOfOperations: 'TIEMPO NETO DE OPERACIONES',
    hours: 'HORAS',
    timeStopped: 'TIEMPO PARADO',
    dateTimeArrival: 'FECHA / HORA DE ARRIBO',
    dateTimeBerthDate: 'FECHA / HORA DE ATRAQUE',
    dateTimeBeginningOperations: 'FECHA / HORA INICIO DE OPERACIONES',
    dateTimeEndOperations: 'FECHA / HORA FINAL DE OPERACIONES',
    dateTimeDeparture: 'FECHA / HORA ZARPE',
    assignedBerthNro: 'MUELLE ASIGNADO NRO',
    errorsSameDateTime: "LA 'FECHA DESDE' NO DEBE SER IGUAL A LA 'FECHA HASTA', POR FAVOR VERIFIQUE",
    errorsFromTimeToTime: "LA 'HORA DESDE' NO DEBE SER SUPERIOR O IGUAL A LA 'HORA HASTA', POR FAVOR VERIFIQUE",
    errorsFromTimeAta: "LA 'HORA DESDE', NO DEBE SER INFERIOR A LA 'HORA DE ATA / ETA', POR FAVOR VERIFIQUE",
    errorsFromTimeAtd: "LA 'HORA DESDE', NO DEBE SER SUPERIOR A LA 'HORA DE ATD', POR FAVOR VERIFIQUE",
    errorsFromCurrentTime: "LA 'HORA DESDE', NO DEBE SER SUPERIOR A LA 'HORA ACTUAL', POR FAVOR VERIFIQUE",
    errorsToTimeAta: "LA 'HORA HASTA', NO DEBE SER INFERIOR A LA 'HORA DE ATA / ETA', POR FAVOR VERIFIQUE",
    errorsToTimeAtd: "LA 'HORA HASTA', NO DEBE SER SUPERIOR A LA 'HORA DE ATD', POR FAVOR VERIFIQUE",
    errorsToCurrentTime: "LA 'HORA HASTA', NO DEBE SER SUPERIOR A LA 'HORA ACTUAL', POR FAVOR VERIFIQUE",
    errorsFromDateAta: "LA 'FECHA DESDE', NO DEBE SER INFERIOR A LA 'FECHA DE ATA / ETA', POR FAVOR VERIFIQUE",
    errorsFromDateAtd: "LA 'FECHA DESDE', NO DEBE SER SUPERIOR A LA 'FECHA DE ATD', POR FAVOR VERIFIQUE",
    errorsFromCurrentDate: "LA 'FECHA DESDE', NO DEBE SER SUPERIOR A LA 'FECHA ACTUAL', POR FAVOR VERIFIQUE",
    errorsToDateAta: "LA 'FECHA HASTA', NO DEBE SER INFERIOR A LA 'FECHA DE ATA / ETA', POR FAVOR VERIFIQUE",
    errorsToDateAtd: "LA 'FECHA HASTA', NO DEBE SER SUPERIOR A LA 'FECHA DE ATD', POR FAVOR VERIFIQUE",
    errorsToCurrentDate: "LA 'FECHA HASTA', NO DEBE SER SUPERIOR A LA 'FECHA ACTUAL', POR FAVOR VERIFIQUE",
    errorsFromTimeOrderBeginning: "LA' HORA DESDE', NO DEBE SER INFERIOR A LA 'HORA DE INICIO DE LA ORDEN', POR FAVOR VERIFIQUE",
    errorsFromTimeOrderFinish: "LA 'HORA DESDE', NO DEBE SER SUPERIOR A LA 'HORA DE CIERRE DE LA ORDEN', POR FAVOR VERIFIQUE",
    errorsToTimeOrderBeginning: "LA 'HORA HASTA', NO DEBE SER INFERIOR A LA 'HORA DE INICIO DE LA ORDEN', POR FAVOR VERIFIQUE",
    errorsToTimeOrderFinish: "LA 'HORA HASTA', NO DEBE SER SUPERIOR A LA 'HORA DE CIERRE DE LA ORDEN', POR FAVOR VERIFIQUE",
    errorsFromDateOrderBeginning: "LA 'FECHA DESDE', NO DEBE SER INFERIOR A LA 'FECHA DE INICIO DE LA ORDEN', POR FAVOR VERIFIQUE",
    errorsFromDateOrderFinish: "LA 'FECHA DESDE', NO DEBE SER SUPERIOR A LA 'FECHA DE CIERRE DE LA ORDEN', POR FAVOR VERIFIQUE",
    errorsToDateOrderBeginning: "LA 'FECHA HASTA', NO DEBE SER INFERIOR A LA 'FECHA DE INICIO DE LA ORDEN', POR FAVOR VERIFIQUE",
    errorsToDateOrderFinish: "LA 'FECHA HASTA', NO DEBE SER SUPERIOR A LA 'FECHA DE CIERRE DE LA ORDEN', POR FAVOR VERIFIQUE",
    weighingReport: 'REPORTE DE PESAJE',
    detailed: 'DETALLADO',
    daysInYard: 'DÍAS EN PATIO',
    returnType: 'TIPO DE DEVOLUCIÓN',
    returnedBins: 'GAMELAS DEVUELTAS',
    transportVehicle: 'TRANSPORTE-VEHICULO',
    detentionCrane: 'PARALIZACION DE GRUAS',
    associationGamelaList:'LISTA DE ASOCIACIÓN DE GAMELAS',
    incidenceOpen: 'INCIDENCIA ABIERTA',
    voyages: 'VIAJES',
    branchByRoles: 'SUCURSALES POR ROL',
    errorsDateTimePleaseCheck: 'Error en las Fechas y horas, por favor, verifique.',
    netoTotal: 'TOTAL NETO',
    grossTotal: 'TOTAL PESO BRUTO',
    tareTotal: 'TOTAL TARA',
    weightTotal: 'TOTAL PESO',
    voyagesTotal: 'TOTAL VIAJES',
    statistics: 'ESTADISTICAS',
    dispacher: 'DESPACHADOR',
    dispatch: 'DESPACHO',
    dispatchDate: 'FECHA DESPACHO',
    weighingDetailReport: 'REPORTE DETALLADO DE PESAJE',
    binWeighing: 'PESAJE DE GAMELAS',
    binWeighingPerYard: 'PESAJE DE GAMELAS POR PATIO',
    weighingReturnedBin: 'PESAJE DE GAMELAS DEVUELTAS',
    reportType: 'TIPO DE REPORTE',
    timesSheetReport: 'REPORTE HOJA DE TIEMPOS',
    timesSheet: 'HOJA DE TIEMPOS',
    inDateFrom: 'EN FECHA DESDE',
    inDateTo: 'EN FECHA HASTA',
    vesselsServedIn:"BUQUES ATENDIDOS EN",
    vesselsServed:"BUQUES ATENDIDOS",
    strategicAlliance: 'ALIANZA ESTRATEGICA',
    mobilizedFull: 'MOVILIZADOS LLENOS',
    mobilizedEmpty: 'MOVILIZADOS VACIOS',
    totals: 'TOTALES',
    total: 'TOTAL',
    dischargeFull: 'DESCARGA LLENOS',
    dischargeEmpty: 'DESCARGA VACIOS',
    loadFull: 'EMBARQUE LLENOS',
    loadEmpty: 'EMBARQUE VACIOS',
    movilizedReturned: 'MOVILIZADOS RETORNADOS',
    finalOperationsReport: 'REPORTE FINAL DE OPERACIONES',
    requiredGamelaChange: 'CAMBIO DE GAMELA REQUERIDO',
    requiredVehicleChange: 'CAMBIO DE VEHICULO REQUERIDO',
    yardBl: 'BL PATIO',
    yardPosition: 'POSICIÓN DEL PATIO',
    declaredTons: 'TONELADAS DECLARADAS',
    orderAssociation: 'ASOCIACION DE ORDEN',
    orderListPerIncidenceType: 'LISTA DE ORDENES POR TIPO DE INCIDENCIA',
    incidenceList: 'LISTA DE INCIDENCIAS',
    errorsStartDateTimeOrderPleaseCheck: 'LA HORA DEBE SER MAYOR A LA HORA DE LA ORDEN, POR FAVOR VERIFIQUE',
    net: 'NETO',
    lost: 'PERDIDO',
    bl: 'BL',
    orderDate: 'F. DE ORDEN',
    delayTime: 'TIEMPO DE DEMORA',
    orderQuantity: 'CANTIDAD DE ORDENES',
    asignResource: 'ASIGNACIÓN DE RECURSO',
    enterEmployeeInfo: 'INGRESE LOS DATOS DEL EMPLEADO',
    employeeInfo: 'DATOS DEL EMPLEADO',
    rotate: 'GIRAR',
    version: 'VERSIÓN',
    review: 'REVISION',
    effectiveTime: 'TIEMPO EFECTIVO',
    incidenceDate: 'FECHA INCIDENCIA',
    yardConsignee: 'CONSIGNATARIO DEL PATIO',
    invalidGreaterThan: 'Debe ser mayor que',
    sspNumber: 'NUMERO SSP',
    sspYear: 'AÑO SSP',
    onePortCompanyBranch: 'UNO DE LOS PUERTOS DEBE SER LA SUCURSAL DE LA EMPRESA',
    notsameAsOrigin: "Puerto de destino no puede ser igual al de origen",
    registerMachineFirst: 'PRIMERO DEBE REGISTRAR LOS DATOS DE LA MAQUINA', 
    idleTime: 'IDLE TIME',
    transactionsTablets: 'TRANSACCIONES - TABLETAS',
    tdr: 'TDR',
    bayView: 'BAY VIEW',
    fullCargoPlan: 'FULL CARGO PLAN',
    yardClient: 'PATIO - CLIENTE',
    vehicleBin: 'VEHICULO - GAMELA',    
    detail: 'DETALLE',
    yardClientSummary: 'SUMARIO POR PATIO - CLIENTE',
    vehicleBinSummary: 'SUMARIO DE VEHICULO - GAMELA',
    tonsWeighed: 'TONELADAS PESADAS',
    estimatedTons: 'TONELADAS ESTIMADAS',
    gamelasQuantity: 'CANTIDAD GAMELAS',
    orders: 'ORDENES',
    transportSummary: 'SUMARIO DE TRANSPORTE',
    hatchCoverLocation: 'UBICACIÓN DE TAPA',
    inspectionDate: 'FECHA DE INSPECCION',
    canceledDocument: 'ANULADO',
    generateOrder: "GENERAR ORDEN", 
    dateFrom: 'FECHA DESDE',
    dateTo: 'FECHA HASTA',
    fullWeighing: 'PESAJE FULL',
    emptyWeighing: 'PESAJE VACIO',
    logisticTimeLine: 'LINEA DE TIEMPO DE LOGISTICA',
    verifyDriverId: "VERIFIQUE LA CEDULA DEL CHOFER",
    verifyLicensePlate: "VERIFIQUE LA PLACA DEL VEHICULO",
    transport: 'TRANSPORTE',
    incidenceType: 'TIPO DE INCIDENCIA',
    noOrder: 'NRO ORDEN',
    emissionDate: 'FECHA DE EMISION',
    shippingDate: 'FECHA EMBARQUE',
    lapse: 'CORTE',
    totalTime: 'TIEMPO TOTAL',
    checker: 'CHEQUEADOR',
    lastDate: 'ULTIMA FECHA',
    currentCircuit: 'CIRCUITO ACTUAL',
    draftInspections: 'INSPECCIONES DE CALADO',
    portPlan: 'PLANO DEL PUERTO',
    ordersByCarrier: 'ORDENES POR TRANSPORTE',
    ordersByDriver: 'ORDENES POR CONDUCTOR',
    ordersByVehicle: 'ORDENES POR VEHICULO',
    ordersByScrapContainer: 'ORDENES POR GAMELA',
    ordersByYard: 'ORDENES POR PATIO',
    dailyReport: 'REPORTE DIARIO',
    finalReport: 'REPORTE FINAL',
    operationalReport: 'REPORTE OPERATIVO',
    tablets: 'TABLETAS',
    ordersList: 'LISTA DE ORDENES',
    incidence: 'INCIDENCIA',
    incidences: 'INCIDENCIAS',
    shipmentOrders: 'ORDENES DE EMBARQUE',
    shipmentOrder: 'ORDEN DE EMBARQUE',
    minimum: 'MÍNIMO',
    operationsSummary: 'RESUMEN DE ACTIVIDADES BUQUE / VIAJE',
    delaysByVessel: 'RETRASOS POR BUQUE',
    delaysByOperator: 'RETRASOS POR OPERADOR',
    delaysPerPort: 'RETRASOS POR PUERTO',
    providers: 'PROVEEDORES',
    ice: 'HIELO',
    water: 'AGUA',
    food: 'COMIDA',
    item: 'ITEM',
    mobilizationOfShipHatch: 'MOVILIZACION DE TAPAS DEL BUQUES',
    mobilizationOfTwinboxes: 'MOVILIZACION DE CAJAS PIÑA', 
    supplementForSealingEmptyContainers:'COMPLEMENTO POR PRECINTADO CONTENEDORES VACIOS (MÍNIMO$50.00)',
    seals: 'PRECINTOS',
    sealingEmptyContainers:'PRECINTADO CONTENEDORES VACIOS',
    shippingContainers: 'EMBARQUE CONTENEDORES',
    containersFull: 'LLENOS',
    containersEmpty: 'VACÍOS',
    unloadContainers: 'DESEMBARQUE CONTENEDORES', 
    metricTonnageAcronym: '(TON)',
    ballast: 'EN LASTRE',
    fuelOil: 'GASOLINA',
    dieselOil: 'GASOIL',
    constant: 'PROVISIONES Y OTROS',
    freshWater: 'AGUA FRESCA',
    errorsCurrentDateTimePleaseCheck: 'LA HORA NO DEBE SER SUPERIOR A LA HORA ACTUAL, POR FAVOR VERIFIQUE',
    errorsStartDateTimePleaseCheck: 'LA HORA NO DEBE SER INFERIOR A LA HORA DE ATA / ETA, POR FAVOR VERIFIQUE',
    errorsHourDateTimePleaseCheck: 'LA HORA HASTA NO DEBE SER INFERIOR O IGUAL A LA HORA DESDE, POR FAVOR VERIFIQUE',
    transactionReverseList: 'LISTA DE REVERSOS DE TRANSACCIONES',
    CurrentPosition: 'POSICION ACTUAL',
    previousPosition: 'POSICIÓN PREVIA',
    transactionReversals: 'REVERSOS DE TRANSACCIONES',
    damageOption: 'DAÑOS',
    searchForContainerToContinue: 'DEBE BUSCAR PRIMERO EL CONTENEDOR PARA PODER CONTINUAR CON EL REGISTRO',
    beginningTurn: 'TURNO DE INICIO',
    finalTurn: 'TURNO FINAL',
    netTime: 'TIEMPO NETO',
    timeOperations: 'TIEMPO DE OPERACIONES',
    beginningHour: 'HORA DE INICIO',
    finalHour: 'HORA FINAL',
    inactivityTime: 'TIEMPO DE INACTIVIDAD',
    delayTimesWithoutMovements: 'TIEMPOS PERDIDOS SIN MOVIMIENTOS', 
    registerDate: 'FECHA REGISTRO',
    editWorkShiftPerVesselType: 'EDITAR TURNO DE TRABAJO POR TIPO DE BUQUE',
    newWorkShiftPerVesselType: 'NUEVO TURNO DE TRABAJO POR TIPO DE BUQUE',
    WorkShiftsPerVesselType: 'TURNOS POR TIPO DE BUQUE',
    WorkShiftPerVesselType: 'TURNO POR TIPO DE BUQUE',
    turns: 'TURNOS',
    stern: 'POPA',
    bow: 'PROA',
    gross: 'PESO BRUTO',
    stowageRoles: 'ROLES DE ESTIBA',
    verifyContainer: "VERIFIQUE SIGLAS DEL CONTENEDOR",
    containerAcronyms: 'SIGLAS DEL CONTENEDOR',
    deleteImage: '¿Esta seguro de eliminar esta imagen?',
    other: 'OTRO',
    CULMINADO: 'CULMINADO',
    correlative: 'CORRELATIVO',
    staffOperator: 'OPERADOR CUADRILLA',
    staffSupervisor: 'SUPERVISOR CUADRILLA',
    supervisor: 'SUPERVISOR',
    uploadDocuments: 'CARGAR DOCUMENTOS',
    clearFilter: 'LIMPIAR FILTRO',
    requiresAtLeastOneSealInspection: 'REQUIERE AL MENOS LA INSPECCION DE UN PRECINTO',
    withoutSeal: 'SIN PRECINTO',
    time: 'TIEMPO',
    errorList: 'LISTA DE ERRORES',
    driverId: 'CEDULA CHOFER',
    driverName: 'NOMBRE CHOFER',
    driverLicenseDateFormat: 'DD-MM-YYYY',
    vehicleClasificationName: 'NOMBRE CLASIFICACION DEL VEHICULO',
    driversAndVehicleList: 'LISTADO DE CHOFERES Y VEHÍCULO',
    driver: 'CHOFER',
    drivers: 'CHOFERES',
    driverLicense: 'NRO. LICENCIA',
    licenseExpirationDate: 'FECHA VENCIMIENTO LICENCIA',
    vehicle: 'VEHÍCULO',
    tpVehicle: 'TIPO VEHICULO',
    tpVehicleName: 'NOMBRE TIPO DE VEHICULO',
    vehiclePlate: 'PLACA',
    vehicleClasification: 'CLASIFICACION VEHICULO',
    vehicleClasific: 'CLASIFICACION DE VEHICULO',
    vehicleType: 'TIPO DE VEHICULO',
    clasificationName: 'NOMBRE DE LA CLASIFICACION',
    listMachine: 'LISTADO DE MAQUINAS',
    registerMachine: 'REGISTRO MAQUINA',
    registerPhotoMachine: 'REGISTRO FOTO DE MAQUINA',
    machineTypeRelationShip: 'ASOCIAR MAQUINA TIPO CARGA',
    serialUnique: 'SERIAL UNICO',
    nameIdDriver: 'NOMRE O CEDULA DEL CHOFER',
    tableReset: 'REINICIAR TABLA',
    aggregate: 'AGREGAR',
    machineTypeName: 'NOMBRE TIPO MAQUINA',
    selectOption: 'SELECCIONE OPCION',
    icon: 'ICONO',
    typeMachine: 'TIPO MAQUINA',
    containers: 'CONTAINERS',
    associatedEquipment: 'EQUIPOS ASOCIADOS',
    machineAssociation: 'ASOCIACIÓN DE MAQUINAS',
    bulk: 'BULK',
    generalCargo: 'GENERAL CARGO',
    clientsActivitiesList: 'LISTADO DE CLIENTES Y ACTIVIDADES',
    activities: 'ACTIVIDADES',
    transactions: 'TRANSACCIONES',
    rifNumber: 'RIF',
    transactionsClient: 'TRANSACCIONES GENERADAS POR CLIENTE',
    motive: 'MOTIVO',
    statusDate:"FECHA ESTATUS",
    previousStatus:"ESTATUS ANTERIOR",
    newStatus:"STATUS NUEVO",
    clientActivities: 'ACTIVIDADES DEL CLIENTE',
    dataTable: 'TABLA DE DATOS',
    updateActivities: 'ACTUALIZAR ACTIVIDADES',
    grabar: 'GRABAR',
    clientsByActivity: 'CLIENTES POR ACTIVIDAD',
    plate: 'PLACA',
    errorSelectActivities: 'Por favor seleccione las Actividades.',
    shippingLineList: 'LISTADO DE LINEAS NAVIERAS',
    assignAcronym: 'ASIGNAR ABREVIATURA',
    assignShippingLineRepresentative: 'ASIGNAR REPRESENTANTE',
    requiresSuplier: 'REQUIERE PROVEEDOR',
    completedSuccessfully: 'SU PROCESO HA FINALIZADO CON EXITO',
    unexpectedError: 'SE HA PRODUCIDO UN ERROR INESPERADO',
    lineRepresentative: 'REPRESENTANTE LINEA',
    inactivateRepresentative: 'INACTIVAR REPRESENTANTE',
    activateRepresentative: 'ACTIVAR REPRESENTANTE',
    shippingLineAbbreviations: 'ABREVIATURAS DE LINEA NAVIERA',
    abbreviation: 'ABREVIATURA',
    newShippingLine: 'LINEA NAVIERA',
    activityName: 'NOMBRE ACTIVIDAD',
    clientRepresentativeName: 'NOMBRE REPRESENTANTE DEL CLIENTE',
    clientAddress: 'DIRECCION CLIENTE',
    clientName: 'NOMBRE CLIENTE',
    newShippingLineName: 'NOMBRE LINEA NAVIERA',
    newShippingLineAddress: 'DIRECCION LINEA NAVIERA',
    newShippingLineAbbreviation: 'ABREVIATURA LINEA NAVIERA',
    inactivateActivity: 'INACTIVAR ACTIVIDAD',
    activateActivity: 'ACTIVAR ACTIVIDAD',
    inactivateRole: 'INACTIVAR ROL',
    activateRole: 'ACTIVAR ROL',
    applySigetMobile: 'APLICA SIGET-MOBILE',
    lostTimeCode: 'CODIGO DE TIEMPO PERDIDO',
    devices: 'DISPOSITIVOS',
    applyTo: 'APLICA A',
    noSearchData: 'SIN DATOS DE BUSQUEDA',
    noRecordsAvailable: 'SIN REGISTROS DISPONIBLES',
    documentQuestion: 'REQUIERE AGREGAR UN DOCUMENTO',
    addFileQuestion: '¿ESTA SEGURO DE SUBIR EL ARCHIVO',
    carrier: 'LINEA NAVIERA',
    containerCode: 'CÓDIGO DE CONTENEDOR',
    transhipmentPort: 'PUERTO DE TRANSICION',
    certificateVGM: 'CERTIFICADO VGM',
    class: 'CLASE',
    length: 'LARGO',
    columnConfig: 'CONFIGURACION DE COLUMNAS',
    portCodes: 'CODIGOS DEL PUERTO',
    hideTableRoles: 'OCULTAR TABLA DE ROLES SELECCIONADOS',
    viewTableRoles: 'VER TABLA DE ROLES SELECCIONADOS',
    delayTdrCode: 'CODIGO DE RETRASOS DEL TDR',
    delayCustomCode: 'CODIGO DE RETRASOS PERSONALIZADO',
    customCode: 'CODIGO PERSONALIZADO',
    chronometer: 'CRONOMETRO',
    dateRange: 'RANGO DE FECHAS',
    app: 'APLICACION',
    errorsDatePleaseCheck: 'Error en las Fechas, por favor, verifique.',
    errorsHourPleaseCheck: 'Error en las horas, por favor, verifique.',
    fin: 'FIN',
    inicio: 'INICIO',
    twinsBox: 'CAJA PIÑA',
    configurations: 'CONFIGURACIONES',
    delayResponsible: 'RESPONSABLE DEL RETRASO',
    others: 'OTROS',
    codesDocuments: 'CODIGOS POR DOCUMENTOS',
    vesselDocuments: 'DOCUMENTOS DEL BUQUE',
    errorsIdleTime: 'EL BUQUE SELECCIONADO NO TIENE REGISTRO DE TIEMPOS',
    errorsUnitSelected: 'EL TIPO DE UNIDAD DE MEDIDA HA SIDO SELECCIONADA PREVIAMENTE',
    geolocation: 'POSICION GEOGRAFICA',
    color: 'COLOR',
    timeCode: 'CODIGO TIEMPO',
    equipmentTotal: 'TOTAL DE EQUIPOS',
    timeReport: 'REPORTE TIEMPOS',
    ALL: 'TODAS',
    dischargePortVisit: 'PUERTO DESCARGA',
    generalContainers: 'CONTENEDORES (GENERAL)', 
    movementsRestow: 'MOVIMIENTOS RESTOW', 
    shipments: 'EMBARQUES', 
    canceled: 'CANCELADOS',
    discharges: 'DESCARGAS',
    vgm: 'VGM',
    vgmDetail: 'DETALLES DE VGM', 
    oversizedContainers: 'CONTENEDORES SOBREDIMENSIONADOS',
    reeferContainers: 'CONTENEDORES REFRIGERADOS',
    imdgContainers: 'CONTENEDORES CARGA PELIGROSA',
    containersByTypeStatus: 'CONTENEDORES DE TIPO-ESTATUS',
    containersByType: 'CONTENEDORES POR TIPO',
    containersByPortLine: 'CONTENEDORES POR PUERTO-LINEA', 
    typeOfLoadPerCrane: 'TIPO DE CARGA POR GRUA',
    listOfPlannedGeneralContainers: 'LISTA DE CONTENEDORES PLANIFICADOS',
    listOfPlannedImdgContainers: 'LISTA DE CONTENEDORES CARGA PELIGROSA PLANIFICADA',
    listOfPlannedMovementsRestow: 'LISTA DE MOVIMIENTOS RESTOW PLANIFICADOS',
    listOfPlannedReeferContainers: 'LISTA DE CONTENEDORES REFRIGERADOS PLANIFICADOS',
    listOfPlannedShipments: 'LISTA DE EMBARQUES PLANIFICADOS',
    listOfPlannedCanceled: 'LISTA DE CANCELADOS PLANIFICADOS',
    listOfPlannedDischarges: 'LISTA DE DESCARGA PLANIFICADA',
    listOfPlannedOversizedContainers: 'LISTA DE CONTENEDORES SOBREDIMENSIONADOS PLANIFICADO',
    listOfPlannedVgmDetail: 'DETALLES DE VGM PLANIFICADOS',
    summaryOfPlannedContainersByTypeStatus: 'SUMARIO DE CONTENEDORES DE TIPO-ESTATUS PLANIFICADO',
    summaryOfPlannedContainersByType: 'SUMARIO DE CONTENEDORES POR TIPO PLANIFICADO',
    summaryOfPlannedContainersByPortLine: 'SUMARIO DE CONTENEDORES POR PUERTO-LINEA PLANIFICADO',
    summaryOfPlannedImdgContainers: 'SUMARIO DE CONTENEDORES CARGA PELIGROSA PLANIFICADO',
    listOfGeneralContainers: 'LISTA DE CONTENEDORES (GENERAL)',
    listOfImdgContainers: 'LISTA DE CONTENEDORES CARGA PELIGROSA',
    listOfMovementsRestow: 'LISTA DE MOVIMIENTOS RESTOW',
    listOfReeferContainers: 'LISTA DE CONTENEDORES REFRIGERADOS',
    listOfShipments: 'LISTA DE EMBARQUES',
    listOfCanceled: 'LISTA DE CANCELADOS',
    listOfDischarges: 'LISTA DE DESCARGA',
    listOfOversizedContainers: 'LISTA DE CONTENEDORES SOBREDIMENSIONADOS', 
    summaryOfContainersByTypeStatus: 'SUMARIO DE CONTENEDORES DE TIPO-ESTATUS',
    summaryOfContainersByType: 'SUMARIO DE CONTENEDORES POR TIPO',
    summaryOfContainersByPortLine: 'SUMARIO DE CONTENEDORES POR PUERTO-LINEA ',
    summaryOfImdgContainers: 'SUMARIO DE CONTENEDORES CARGA PELIGROSA',
    summaryCraneLoadType: 'SUMARIO DE TIPO DE CARGA POR GRUA',
    transactionReport: 'REPORTE DE TRANSACCIONES',
    noRecordsAvailbleReport: 'NO HAY REGISTROS DISPONIBLES PARA GENERAR EL REPORTE',
    printed: 'IMPRESO',
    reportSuccessfully: 'REPORTE GENERADO EXITOSAMENTE',
    reportError: 'ERROR AL GENERAR EL REPORTE',
    discountedTdr: 'CON DESCUENTOS',
    pageReport: 'PÁGINA',
    ofReport: 'DE',
    delay: 'TIEMPO',
    timeCodeDetails: 'DETALLES DEL CODIGO DE TIEMPO',
    applyTdr: 'APLICA AL TDR',
    currentTime: 'TIEMPO EN CURSO',
    serial: 'SERIAL',
    serials: 'SERIALES',
    berthVisit: 'ATRAQUE',
    positionContainer: 'POSICIÓN',
    isoCodes: 'CODIGOS ISO',
    equivalent: 'EQUIVALENTE',
    image: 'IMAGEN',
    images: 'IMAGENES',
    selectedImage: 'IMAGEN SELECCIONADA',
    toPage: 'A',
    records: 'REGISTROS',
    discharge:'DESCARGA',
    modality: 'MODALIDAD',
    height: 'ALTO',
    actualConditions: 'CONDICIONES ACTUALES',
    //Angel
    resultEqualValue: 'RESULTADO = VALOR ',
    locationName: 'NOMBRE DE LA UBICACIÓN',
    ID: "IDENTIFICACIÓN",
    password: "CONTRASEÑA",
    userIdentificationInfo:"DATOS DE INDENTIFICACIÓN DEL USUARIO",
    invalidContainerCode: "CODIGO DE CONTENEDOR INVALIDO",
    checkDigit:"CHECK-DIGIT",
    asignUserRole: 'ASIGNAR ROLES DE USUARIOS',
    rolesAssigned: 'ROLES ASIGNADOS',
    rolesToBeAssigned: 'ROLES POR ASIGNAR',
    selectedRoles: 'ROL(ES) SELECCIONADO(S)',
    transactionHourDate: "FECHA HORA TRANSACCION", 
    generateReport: "GENERAR REPORTE", 
    holiday: 'FERIADO',
    holidayDay: 'DIA FERIADO',
    holidayDayName: 'NOMBRE DE DIA FERIADO',
    holidayDate: 'FECHA FERIADO',
    holidayType: 'TIPO FERIADO',
    holidayTypeName: 'NOMBRE TIPO FERIADO',
    calendary: 'CALENDARIO',
    colorIdentification: 'color Identificación',
    standard: "ESTANDAR",
    standardDocumentation:'DOCUMENTACION ESTANDAR',
    files: 'ARCHIVOS',
    regulatoryBody: "ENTE REGULADOR",
    path: 'RUTA',
    //MARIA
    Alias: 'NOMBRE ALIAS',
    registeredPreviouslyCircuit: 'CIRCUITO REGISTRADO PREVIAMENTE',
    modalityName: 'NOMBRE DE MODALIDAD',
    CircuitAssociation: 'ASOCIACIÓN DE CIRCUITO',
    ModalityAssociation: 'ASOCIACIÓN MODALIDAD',
    Up: 'SUBIR',
    Down: 'BAJAR',
    IdentificationClient: 'IDENTIFICACION CLIENTE',
    ClientType: 'TIPO DE CLIENTE',
    OperationType: 'TIPO DE OPERACION',
    representativeName: 'NOMBRE REPRESENTATIVO CLIENTE',
    CancellationReasons: 'MOTIVOS DE CANCELACION',
    CancellationReason: 'MOTIVO CANCELACIÓN',
    Cancellation: 'CANCELACION',
    CancellationReasonName: 'NOMBRE MOTIVO CANCELACION',
    Vehicles: 'VEHICULOS',
    Bin: 'GAMELAS', 
    listDriverVehicleBin: 'LISTA DE CHOFERES, VEHICULOS Y GAMELAS',
    entryOrders: 'ORDENES DE INGRESO',
    shipmentOrders: 'ORDENES DE EMBARQUE',
    seeOrders: 'VER ORDENES',
    Activity: 'ACTIVIDAD',
    AssociationActivityClient: 'ACTIVIDAD - CLIENTES',
    Association: 'ASOCIACIÓN',
    //JAVIER
    Partial: 'PARCIAL',
    DaysInOperation: 'DIAS EN OPERACIÓN',
    ReceptionByBl: 'RECEPCIÓN POR BL',
    ContainerStatus: 'ESTATUS DEL CONTENEDOR',
    SearchResult: 'RESULTADO DE LA BUSQUEDA',
    QuantityInInventory: 'CANTIDAD EN INVENTARIO',
    WeightInInventory: 'PESO EN INVENTARIO',
    VolumeInInventory: 'VOLUMEN EN INVENTARIO',
    OrderOfEntry: 'ORDEN DE INGRESO',
    ActivityTime: 'TIEMPO DE LA ACTIVIDAD',
    ListNotPositioned: 'LISTA DE NO POSICIONADOS',
    BolipuertoCertificateEntry: 'ACTA RECEP. BP',
    Cant: 'CANT.',
    DispatchOrder: 'ORDEN DE DESPACHO',
    Abbreviatedcertified: 'CERTIF.',
    PlanQuantity: 'CANTIDAD PLAN.',
    RecepQuantity: 'CANTIDAD RECEP.',
    DespQuantity: 'CANTIDAD DESP.',
    CertifQuantity: 'CANTIDAD CERTIF.',
    PlanWeight: 'PESO PLAN.',
    RecepWeight: 'PESO RECEP.',
    DespWeight: 'PESO DESP.',
    CertifWeight: 'PESO CERTIF.',
    PlanVolume: 'VOLUMEN PLAN.',
    RecepVolume: 'VOLUMEN RECEP.',
    DespVolume: 'VOLUMEN DESP.',
    CertifVolume: 'VOLUMEN CERTIF.',
    ContainersInInventory: 'EQUIPOS EN INVENTARIO',
    PrintedBy: 'IMPRESO POR',
    CertifiedBy: 'CERTIFICADO POR',
    PreparedBy: 'ELABORADO POR',
    KeySets: 'JUEGOS DE LLAVE',
    Extinguisher: 'EXTINTOR',
    Battery: 'BATERIA',
    BolipuertoAct: 'ACTA BOLIPUERTO',
    BlHouse: 'BL HOUSE',
    GoToBlMaster: 'IR A BL MASTER',
    YardYard: 'PATIO',
    ReceivedId: 'CI RECIBIDO',
    ReceivedBy: 'RECIBIDO POR',
    OriginYard: 'PATIO ORIGEN',
    DaysInYardWarehouse: 'DÍAS EN PATIO / ALMACÉN',
    YardWarehouseOrigin: 'UBICACIÓN ORIGEN',
    YardWarehouse: 'PATIO / ALMACÉN',
    PackagingOrigin: 'EMBALAJE ORIGEN',
    SerialOrigin: 'SERIAL ORIGEN',
    WeightOrigin: 'PESO ORIGEN',
    AbbreviatedPlanned: 'PLAN.',
    AbbreviatedReception: 'RECEP.',
    AbbreviatedDispatch: 'DESP.',
    UserCertifies: 'USUARIO CERTIFICA',
    CertificateDate: 'FECHA ACTA',
    CertificateNro: 'NRO ACTA',
    CertificateEntry: 'ACTA DE RECEPCIÓN',
    CertificatesEntry: 'ACTAS DE RECEPCIÓN',
    Acta: 'ACTA',
    statusCertificate: 'ESTATUS ACTA',
    daysInYard:"DÍAS EN PATIO",
    ApplySerializatiom: 'APLICA SERIALIZACIÓN',
    PackagingIsFullySerialized: 'EL EMBALAJE ESTA COMPLETAMENTE SERIALIZADO',
    Shopper: 'COMPRADOR',
    clientConsignee: 'CLIENTE CONSIGNATARIO',
    PackagingIsSerialized: 'EL EMBALAJE ESTA SERIALIZADO',
    PackagingSuccessfullySaved: 'EMBALAJE GUARDADO EXITOSAMENTE',
    SerializationByPackaging: 'SERIALIZACIÓN  POR EMBALAJE',
    SerializationSavedSuccessfully: 'SERIALIZACIÓN  GUARDADA EXITOSAMENTE',
    NotSerializedQuantity: 'LA CANTIDAD NO PUEDE SER MENOR QUE LA CANTIDAD SERIALIZADA',
    NotSerializedWeight: 'EL PESO NO PUEDE SER MENOR QUE EL PESO SERIALIZADO',
    NotSerializedVolume: 'EL VOLUMEN NO PUEDE SER MENOR QUE EL VOLUMEN SERIALIZADO',
    PackagingQuantity: 'EMBALAJE / CANTIDAD',
    Serialized: 'SERIALIZADO',
    packagingNoySerializedQuantity:"CANTIDAD DE EMBALAJE NO CONCUERDA CON CANTIDAD SERIALIZADA",
    applySerialization :"APLICA SERIALIZACIÓN",
    Serialization: 'SERIALIZACIÓN',
    CurrentSituation: 'SITUACIÓN ACTUAL',
    DaysInPort: 'DÍAS EN PUERTO',
    house: 'HOUSE',
    FirstEvent: 'PRIMER EVENTO',
    FirstEventDate: 'F. PRIMER EVENTO',
    LastEvent: 'ULTIMO EVENTO',
    LastEventDate: 'F. ULTIMO EVENTO',
    BlNro: 'NRO BL',
    BlQuery: 'CONSULTA DE BL',
    TotalCargo: 'CARGA TOTAL',
    MOVILIZADO: 'MOVILIZADO',
    CertifiedQuantity: 'CANTIDAD CERTIFICADA',
    ReceivedQuantity: 'CANTIDAD RECEPCIONADA',
    CertifiedPendingReceived: 'CANTIDAD PENDIENTE POR RECEPCIONAR',
    BreakBulk: 'GENERAL SUELTA',
    ToIdentify: 'POR IDENTIFICAR',
    SituationPackaging: 'SITUACIÓN POR EMBALAJE',
    FileAlert: 'SE RECOMIENDA LEER EL INTRUCTIVO ANTES DE REALIZAR EL LLENADO DEL ARCHIVO',
    Identified: 'IDENTIFICADO',
    VisitSelected: 'SE DEBE SELECCIONAR UNA VISITA',
    LastLoad: 'ULTIMO EMBARQUE',
    TonLoaded: 'TON - EMBARCADAS',
    quantityExecuted: 'CANTIDAD EJECUTADA',
    AssignAll: 'ASIGNAR TODO',
    SumOfWeightPerDetail: 'SUMATORIA DEL PESO POR DETALLE',
    SumOfVolumePerDetail: 'SUMATORIA DEL VOLUMEN POR DETALLE',
    SumOfQuantityPerDetail: 'SUMATORIA DE LA CANTIDAD POR DETALLE',
    DistributionInDeck: 'DISTRIBUCION EN CUBIERTA',
    DistributionInHold: 'DISTRIBUCION EN BODEGA',
    DistributionInDeckHold: 'DISTRIBUCION EN CUBIERTA / BODEGA',
    CustomInspetion: "RECONOCIDOS",
    BlContainerList: 'LISTA DE CONTENEDORES DEL BL',
    AssignmentToDeck: 'ASIGNACIÓN A CUBIERTA',
    PlannedYard: 'PATIO PLANIFICADO',
    ItemPreviouslyRegistered: 'ITEM PREVIAMENTE REGISTRADO',
    ApplyPackaging: 'APLICA EMBALAJE',
    TotalVolume: 'VOLUMEN TOTAL',
    QuantityAvailabilityPerDetail: 'DISPONIBILIDAD DE CANTIDAD POR DETALLE',
    QuantityAvailabilityPerPackaging: 'DISPONIBILIDAD DE CANTIDAD POR EMBALAJE',
    ModalityOfOperations: 'MODALIDAD DE OPERACIONES',
    AvailabilityByDetail: 'DISPONIBILIDAD POR DETALLE',
    AvailabilityByPackaging: 'DISPONIBILIDAD POR EMBALAJE',
    OversizedEquipment: 'EQUIPOS SOBREDIMENSIONADOS',
    DangerousGood: 'CARGA PELIGROSA',
    ContainerList: 'LISTA DE CONTENEDORES',
    TransferredFile: "ARCHIVO TRANSFERIDO",
    ExternalPackaging: 'EMBALAJE EXTERNO',
    InternalPackaging: 'EMBALAJE INTERNO',
    DateOfDischarge: 'FECHA DE DESCARGA',
    WasLoadedSuccessFully: 'SE CARGO DE MANERA EXITOSA',
    PlannedBlList: 'LISTA DE BL PLANIFICADOS',
    LastReception: 'ÚLT. RECEPCIÓN',
    LastDispatch: 'ÚLT. DESPACHO',
    Unoccupied: 'DESOCUPADOS',
    Ocupados: 'OCUPADOS',
    YardTeus: 'TEUS EN PATIO',
    PortSituation: 'SITUACIÓN DE PUERTO',
    dispached: 'DESPACHADO',
    ContainerEquipment: 'EQUIPO',
    VesselsAtBerth: 'BUQUES ATRACADOS',
    InDock: 'EN MUELLE',
    TruckWeighingsModality: 'MODALIDAD DE PESAJE',
    TheDateCannotBeLessThanTheDateOfDispatch: 'LA FECHA NO PUEDE SER MENOR A LA FECHA DE DESPACHO',
    Cabotage: 'CABOTAJE',
    Recognized: 'RECONOCIDOS',
    Despachados: 'DESPACHADOS',
    Recepcionados: 'RECEPCIONADOS',
    received: 'RECEPCIONADO',
    Stored: 'ALMACENADOS',
    YardSituation: 'SITUACIÓN',
    TeusCapacity: 'CAPACIDAD DE TEUS',
    TeusAvailable: 'TEUS DISPONIBLES',
    TeusOccupied: 'TEUS OCUPADO',
    ListInvalidPositionContainer: 'LISTA DE CONTENEDORES DE POSICIONES NO VÁLIDAS',
    ErrorPleaseCheck: 'LA FECHA Y HORA NO DEBE SER MENOR O IGUAL A LA',
    ErrorYearOneCurrentDatePleaseCheck: 'LA FECHA Y HORA NO DEBE SER MENOR O IGUAL A LA FECHA A MENOS UN AÑO DE LA FRCHA ACTUAL, POR FAVOR VERIFIQUE',
    RepositionedContainers: 'EQUIPOS REPOSICIONADOS',
    SelectedContainers: 'EQUIPOS SELECCIONADOS',
    ErrorAtdDatePleaseCheck: 'LA FECHA Y HORA NO PUEDE SER SUPERIOR O IGUAL A LA ATD, POR FAVOR VERIFIQUE',
    ErrorAtaDatePleaseCheck: 'LA FECHA Y HORA NO PUEDE SER MENOR O IGUAL A LA ATA, POR FAVOR VERIFIQUE',
    AtLeastContainerRequired: 'SE REQUIERE AL MENOS 1 CONTENEDOR',
    DispachesMassiveToVessel: 'DESPACHOS MASIVOS A BUQUE',
    SealAssignmentList: 'LISTADO DE ASIGNACIÓN DE PRECINTOS',
    SealAssignment: 'ASIGNACIÓN DE PRECINTOS',
    MaxImage: 'IMAGEN MAXIMA',
    ReceptionCanceledContainers: 'RECEPCIÓN DE EQUIPOS CANCELADOS',
    CanceledContainers: 'EQUIPOS CANCELADOS',
    TransacAcronym: 'TRANS',
    ExternalYardReception: 'RECEPCION PATIO EXTERNO',
    LastTransaction: 'ULTIMA TRANSACCION',
    ExternYard: 'PATIO EXTERNO',
    Entry: 'INGRESO',
    MissingBranchId: 'FALTA ID DE LA SUCURSAL',
    YardNotConfiguationAssigned: 'EL PATIO NO TIENE UNA CONFIGURACIÓN ASIGNADA',
    RecognitionZone: 'ZONA DE RECONOCIMIENTO',
    RecognitionDate: 'FECHA RECONOCIMIENTO',
    RecognitionUser: 'USUARIO RECONOCIMIENTO',
    DispatchUser: 'USUARIO DESPACHO',
    NewEntry: 'NUEVO INGRESO',
    ErrorReceptionDatePleaseCheck: 'LA FECHA Y HORA NO DEBE SER SUPERIOR O IGUAL A LA FECHA DE RECEPCIÓN, POR FAVOR VERIFIQUE',
    ErrorConfirmationDatePleaseCheck: 'LA FECHA Y HORA NO PUEDE SER MENOR O IGUAL A LA FECHA DE CONFIMACIÓN, POR FAVOR VERIFIQUE',
    ErrorDispatchDatePleaseCheck: 'LA FECHA Y HORA NO DEBE SER SUPERIOR O IGUAL A LA FECHA DE DESPACHO, POR FAVOR VERIFIQUE',
    ErrorLastMovementDatePleaseCheck: 'LA FECHA Y HORA NO DEBE SER SUPERIOR O IGUAL A LA FECHA DEL ULTIMO MOVIMIENTO, POR FAVOR VERIFIQUE',
    ViewContainer: 'VER EQUIPO',
    Measures: 'MEDIDAS',
    RecognitionList: 'LISTA DE RECONOCIMIENTO',
    ListOfDispatchedContainer: 'LISTA DE EQUIPO DESPACHADOS',
    Pending: 'PENDIENTE',
    DocumentationOfTicketOut: 'DOCUMENTACIÓN DEL PASE DE SALIDA',
    EirDocumentation: 'DOCUMENTACIÓN EIR',
    ListOfDrivers: 'LISTA DE CHOFERES',
    VehiclesList: 'LISTA DE VEHÍCULOS',
    OversizedMovement: 'MOVIMIENTO SOBREDIMENSIONADO',
    Auxiliary: 'AUXILIAR',
    PositioningDate: 'FECHA POS.',
    ListOfReceivedContainers: 'LISTA DE EQUIPOS RECEPCIONADOS',
    ListOfReceivedBls: 'LISTA DE BLS RECEPCIONADOS',
    Area: 'ÁREA',
    ReceptionUser: 'USUARIO RECEPCION',
    DaysInYard: 'DÍAS EN PATIO',
    ReceptionDate: 'FECHA RECEPCION',
    ContainersInYard: 'EQUIPOS EN PATIO',
    TheDateCannotBeLessThanTheDateOfReception: 'LA FECHA NO PUEDE SER MENOR A LA FECHA DE RECEPCION',
    TheDateCannotBeLessThanTheDateOfConfirmation: 'LA FECHA NO PUEDE SER MENOR A LA FECHA DE CONFIRMACIÓN',
    AtLeastSealRequired: 'SE REQUIERE AL MENOS 1 PRECINTO',
    TheRoleDoesNotHaveMenuAssigned: 'EL ROL NO TIENE UN MENÚ ASIGNADO',
    OrdersByLapse: 'ORDENES POR CORTE',
    Agency: 'AGENCIA',
    ToLoad: 'POR EMBARCAR',
    AveragePerBin: 'PROMEDIO POR GAMELA',
    Accumulated: 'ACUMULADO',
    LoadedPerTurns: 'EMBARCADAS POR TURNOS',
    IncidenceName: 'NOMBRE DE INCIDENCIA',
    BinName: 'NOMBRE DE LA GAMELA',
    DeviceCode: 'CÓDIGO DE DISPOSITIVO',
    Slot: 'POSICIÓN',
    CargoStatus: 'ESTATUS DE CARGA',
    PlaceOfDelivery: 'LUGAR DE ENTREGA',
    CargoOriginPort: 'PUERTO DE ORIGEN DE LA CARGA',
    TransshipmentPortCode: 'CODIGO DE PUERTO DE TRANSBORDO',
    TransshipmentPort: 'PUERTO DE TRANSBORDO',
    IsoInfo: 'INFORMACION DE ISO',
    GroupType: 'TIPO DE GRUPO',
    SizeFt: 'TAMAÑO (FT)',
    DangerousInfo: 'INFORMACIÓN DE PELIGRO',
    UnNumber: 'UN NUMBER',
    IMDGPageNO: 'NRO. PAGINA IMDG',
    FlashPoint: 'PUNTO DE INFLAMACIÓN',
    PackagingGroup: 'GRUPO DE EMBALAJE',
    oversize: 'SOBREDIMENSIÓN',
    OversizeInfo: 'INFORMACION DE EQUIPOS SOBREDIMENSIONADOS',
    DimFront: 'MEDIDA FRONTAL',
    DimBack: 'MEDIDA TRASERA',
    DimLeft: 'MEDIDA IZQUIERDA',
    DimRight: 'MEDIDA DERECHA',
    DimTop: 'MEDIDA SUPERIOR',
    DimFrontMov: 'FRONTAL',
    DimBackMov: 'TRASERA',
    DimLeftMov: 'IZQUIERDA',
    DimRightMov: 'DERECHA',
    DimTopMov: 'SUPERIOR',
    reefer: 'REFRIGERADO',
    ReeferInfo: 'INFORMACIÓN DE EQUIPOS REFRIGERADOS',
    ReeferSetting: 'CONFIGURACIÓN DE EQUIPOS REFRIFERADOS',
    MinimumRange: 'RANGO MINIMO',
    MaximumRange: 'RANGO MAXIMO',
    ModifyShipmentOrder: 'MODIFICAR ORDEN DE EMBARQUE',
    HoldHatchCover: 'TAPA DE LA BODEGA',
    BABOR: 'BABOR',
    CENTRO: 'CENTRO',
    ESTRIBOR: 'ESTRIBOR',
    Underdeck: 'BAJO CUBIERTA',
    TierRows: 'NIVEL-FILAS',
    RowsToStartboard: 'FILAS A ESTRIBOR',
    RowsToPort: 'FILAS A BABOR',
    Disabled: 'DESHABILITADO',
    CrosssLine: 'EJE CENTRAL',
    Confirm: 'CONFIRMAR',
    Hazard: 'HABILITADO CARGA PELIGROSA',
    Hazard_Restriction: 'RESTRINGIDO CARGA PELIGROSA',
    unNumber:'UN NUMBER',
    hazardClass:'CLASES IMDG',
    Not_Exist: 'NO EXISTE',
    Reefer_Plug: 'CARGA REFRIGERADA',
    EstimatedBin: 'GAMELA ESTIMADAS',
    Inactivate: 'INACTIVAR',
    BinReturn: 'DEVOLUCIÓN DE GAMELA',
    IncidenceGroup: 'GRUPO DE INCIDENCIA',
    IncidentList: 'LISTA DE INCIDENCIA',
    FullLessTare: 'EL PESO FULL NO PUEDE SER INFERIOR AL PESO TARA',
    ValueNotNegative: 'EL VALOR NO PUEDE SER NEGATIVO',
    CapacityEstimated: 'CAPACIDAD ESTIMADA',
    BinTareweightlist: 'LISTA DE PESO TARA DE LA GAMELA',
    TareWeightlist: 'LISTA DE PESO TARA',
    BinOrderNoList: 'LISTA DE NRO ORDEN DE LA GAMELA',
    OrderNolist: 'LISTA DE NRO ORDEN',
    TareWeightAssociateBinMustSelected: 'SE DEBE SELECCIONAR EL PESO TARA ASOCIADA A LA GAMELA',
    OrderNoAssociateBinMustSelected: 'SE DEBE SELECCIONAR EL NRO ORDEN ASOCIADA A LA GAMELA',
    FullWeighingHistoricOrderNo: 'HISTÓRICO DE PESAJE FULL DEL NRO ORDEN',
    TareWeightBinHistoricVehicle: 'HISTÓRICO DEL PESO TARA DE GAMELA - VEHÍCULO',
    TareWeightBinHistoric: 'HISTÓRICO DEL PESO TARA DE GAMELA',
    BinTareWeight: 'PESO TARA DE GAMELA',
    FullWeighingHistoric: 'HISTÓRICO DE PESAJE FULL',
    billOfLadingDocument: 'DOCUMENTO DE MANIFIESTO DE CARGA',
    InspectionsRegistre: 'REGISTRO DE INSPECCIÓN',
    TransportCertification: 'CERTIFICACIÓN DE TRANSPORTE',
    YardCertification: 'CERTIFICACIÓN EN PATIO',
    OrdersWeight: 'ORDENES - PESO',
    DateWeighing: 'FECHA PESAJE',
    TotalHoldCertification: 'TOTAL CERTIFICACIÓN BODEGA',
    InspectionRange: 'RANGO DE INSPECCIÓN',
    GeneralSummary: 'RESUMEN GENERAL',
    DetailsOfBinsWeighted: 'DETALLES DE GAMELAS PESADAS',
    DetailsOfBinsWeightless: 'DETALLES DE GAMELAS SIN PESAR',
    DetailsOfBinsLoaded: 'DETALLES DE GAMELAS EMBARCADAS',
    ScaleMovements: 'MOVIMIENTOS EN BASCULA',
    BinsWeighted: 'GAMELAS PESADAS',
    BinsWeightless: 'GAMELAS SIN PESAR',
    BinsLoaded: 'GAMELAS EMBARCADAS',
    WeightCorrection: 'CORRECCION PESO',
    WeighingScaleWeight: 'PESO BASCULA',
    InternalClient: 'CLIENTE INTERNO',
    InternalBl: 'BL INTERNO',
    Detallado: 'DETALLADO',
    Tons: 'TONELADAS',
    BinsReturned: 'DEVOLUCIÓN DE GAMELAS',
    DifferenceWeight: 'DIFERENCIAL DE PESO',
    FirstFullWeight: 'PRIMER PESAJE FULL',
    LastFullWeight: 'ULTIMO PESAJE FULL',
    MayorFullWeight: 'MAYOR PESO FULL',
    ReturnedDate: 'FECHA RETORNO',
    Combustible: 'COMBUSTIBLE',
    OnBoard: 'A BORDO',
    LastMovement: 'ULTIMO MOVIMIENTO',
    NetHrs: 'HRS NETA',
    OrderIssued: 'ORD. EMITIDA',
    TotalIncidenceByTransport: 'TOTAL INCIDENCIA POR TRANSPORTE',
    TotalByIncidence: 'TOTAL POR INCIDENCIA',
    BulkCarrierShips: 'BUQUE GRANELERO',
    ServiceRequest: 'SOLICITUD DE SERVICIO',
    TotalTonsLoaded: 'TOTAL DE TONELADAS EMBARCADAS',
    Shift: 'TURNO',
    TonsAveragePerTruck: 'TONELADAS PROMEDIO POR GANDOLA',
    DateOfBerthing: 'FECHA DE ATRAQUE',
    MetalScrapTon: 'TONELADA DE CHATARRA',
    ShippingLines: 'LÍNEAS NAVIERAS',
    MovementsPerCrane: 'MOVIMIENTO POR GRUA',
    HrsInOPER: 'HRS EN OPER.',
    LoadDischarge: 'CARGA-DESCARGA',
    EffectiveHours: 'HRS EFECTIVAS',
    HoursDelays: 'HRS RETRASO',
    NetDelayHours: 'HRS RETRASO NETO',
    StrategicAlliance: 'ALIANZA ESTRATÉGICA',
    errorsOperativReport: 'EL BUQUE SELECCIONADO NO TIENE REPORTE OPERATIVO',
    errorsTimeslost_StrategicAlliance: 'EL BUQUE SELECCIONADO NO TIENE REPORTE DE TIEMPO PERDIDO - ALIANZA ESTRATÉGICA',
    VesselsGrossIs: 'EL PESO BRUTO DEL BUQUE ES',
    CurrentGrossIs: 'EL PESO BRUTO ACTUAL ES',
    DelayCustom: 'RETRASO PERSONALIZADO',
    TimePerTypeVessel: 'TIEMPO POR TIPO DE BUQUE',
    ErrorAtaPleaseCheck: 'LA FECHA Y HORA NO DEBE SER INFERIOR AL ATA, POR FAVOR VERIFIQUE',
    ErrorEtaPleaseCheck: 'LA FECHA Y HORA NO DEBE SER INFERIOR AL ETA, POR FAVOR VERIFIQUE',
    ErrorAtdPleaseCheck: 'LA FECHA Y HORA NO DEBE SER SUPERIOR AL ATD, POR FAVOR VERIFIQUE',
    ErrorEtdPleaseCheck: 'LA FECHA Y HORA NO DEBE SER SUPERIOR AL ETD, POR FAVOR VERIFIQUE',
    ErrorFromToPleaseCheck: 'LA FECHA DESDE NO DEBE SER SUPERIOR O IGUAL A LA FECHA HASTA, POR FAVOR VERIFIQUE',
    ErrorToFromPleaseCheck: 'LA FECHA HASTA NO DEBE SER SUPERIOR O IGUAL A LA FECHA DESDE, POR FAVOR VERIFIQUE',
    ErrorCurrentDatedPleaseCheck: 'LA FECHA Y HORA NO DEBE SER SUPERIOR A LA FECHA ACTUAL, POR FAVOR VERIFIQUE',
    ErrorNotLowerDesconsolidationPleaseCheck: 'LA FECHA Y HORA NO DEBE SER INFERIOR A LA FECHA Y HORA DE DESCONSOLIDACIÓN',
    ErrorOrderDateIssuePleaseCheck: 'LA FECHA Y HORA NO DEBE SER INFERIOR A LA FECHA EMITIDA DE LA ORDEN, POR FAVOR VERIFIQUE',
    ErrorOrderDateFinishPleaseCheck: 'LA FECHA Y HORA NO DEBE SER SUPERIOR A LA FECHA DE EMBARQUE DE LA ORDEN, POR FAVOR VERIFIQUE',
    UnitMeasurementAssociation: 'ASOCIACIÓN DE UNIDADES DE MEDIDA',
    TotalOrders: 'TOTAL ORDENES',
    Received: 'RECEPCIONADAS',
    UniqueId: 'ID UNICO',
    TheVisitHasNotStartedOperations: 'LA VISITA NO A INICIADO OPERACIONES',
    RelocationGuide: 'RELOCATION GUIDE',
    OpenOrders: 'ORDENES ABIERTAS',
    ClosedOrders: 'ORDENES CERRADAS',
    OrdersInactive: 'ORDENES INACTIVAS',
    InOperations: 'EN OPERACIONES',
    SeeEntryOrdersByDriver: 'VER ORDENES DE INGRESOS POR CHOFER',
    SeeShipmentOrdersByDriver: 'VER ORDENES DE EMBARQUE POR CHOFER',
    SeeEntryOrdersByVehicle: 'VER ORDENES DE INGRESOS POR VEHICULO',
    SeeShipmentOrdersByVehicle: 'VER ORDENES DE EMBARQUE POR VEHICULO',
    SeeShipmentOrdersByBin: 'VER ORDENES DE EMBARQUE POR GAMELA',
    HourRangesMachineWork: 'RANGOS DE HORAS DE TRABAJO DE MAQUINA',
    HoursRange: 'RANGO DE HORAS',
    RangesOfCraneWorkingHours: 'RANGOS DE HORAS DE TRABAJO DE GRÚA',
    VesselSide: 'A COSTADO DE BUQUE',
    AnulationDate: 'FECHA ANULACIÓN',
    AnulationDeviceCode: 'DISPOSITIVO ANULACIÓN',
    AnulationLogin: 'USUARIO ANULACIÓN',
    ObservationAnulation: 'OBSERVACIÓN ANULACIÓN',
    TimeInPort: 'TIEMPO EN PUERTO',
    AuthoritiesWaitingTime: 'TIEMPO DE ESPERA DE AUTORIDADES',
    TimeInService: 'TIEMPO EN SERVICIO',
    TimeOfInspectionsAndVisitArrival: 'TIEMPO DE INSPECCIÓN Y VISITA (ARRIBO)',
    FreePracticeTimeArrival: 'TIEMPO LIBRE PRACTICA (ARRIBO)',
    TimeInDock: 'TIEMPO EN MUELLE',
    FreePracticeTimeDeparture: 'TIEMPO LIBRE PRACTICA (DESATRAQUE)',
    OtherTimes: 'OTROS TIEMPOS',
    TimeOfInspectionsAndVisitDeparture: 'TIEMPO DE INSPECCIÓN Y VISITA (SALIDA)',
    GrossTimeOfOperations: 'TIEMPO BRUTO DE OPERACIONES',
    Completed: 'COMPLETADO',
    Occurrence: 'OCURRENCIA',
    UseCtrlMousewheelInOrderToZoom: 'USE CTRL + RUEDA DEL RATON PARA HACER ZOOM',
    ActivitiesNotExecuted: 'ACTIVIDADES NO EJECUTADAS',
    Inactivity: 'INACTIVIDAD',
    Management: 'GERENCIALES',
    TimeLine: 'LINEA DE TIEMPO',
    Export: 'EXPORTACIÓN',
    Import: 'IMPORTACIÓN',
    Operations: 'OPERACIONES',
    CraneHrs: 'HRS GRUA',
    MovsXHrNet: 'MOVS X HORA NETA',
    MovsXHrGross: 'MOVS X HORA BRUTO',
    MovementsPerHourNet: 'MOVIMIENTOS POR HORA NETA',
    MovementsPerHourGross: 'MOVIMIENTOS POR HORA BRUTO',
    MovementsPerHourTerminal: 'MOVIMIENTOS POR HORA TERMINAL',
    PortActivities: 'ACTIVIDADES PORTUARIAS',
    MovementsAndInactivityTimePerCrane: 'MOVIMIENTOS Y TIEMPOS DE INACTIVIDAD POR GRUA',
    OrderAffectedList: 'LISTA DE ORDENES AFECTADAS',
    OverrideOrderAndWeighingEvents: 'ANULAR ORDEN(ES) Y EVENTOS DE PESAJE',
    OverrideOrder: 'ANULAR ORDEN(ES)',
    OriginBin: 'GAMELA DE ORIGEN',
    NoChangesToModify: 'SIN CAMBIOS PARA MODIFICAR',
    EnterContainerCode: 'INGRESE CÓDIGO CONTENEDOR',
    EnterBlCode: 'INGRESE CÓDIGO BL',
    Percentage: 'PORCENTAJE',
    Reefers: 'REFRIGERADOS',
    Tank: 'TANQUE',
    TheCraneIsNotOperational: 'LA GRUA NO ESTÁ OPERATIVA',
    VisitSummary: 'RESUMEN VISITA',
    InactivateMachineVisit: 'INACTIVAR MAQUINA - VISITA',
    ErrorLog: 'LOG DE ERRORES',
    TransferDate: 'FECHA DE TRANSFERNCIA',
    TransferUser: 'USUARIO DE TRANSFERNCIA',
    ErrorMessage: 'MENSAJE DE ERROR',
    UsersWithPermission: 'USUARIOS CON PERMISO',
    UsersTransactions: 'USUARIOS - TRANSACCIONES',
    TabletEnabled: 'TABLETA HABILITADAS',
    TableTransactions: 'TABLETA - TRANSACCIONES',
    NumberOfErrors: 'CANTIDAD DE ERRORES',
    NumberOfTransactions: 'CANTIDAD DE TRANSACCIONES',
    NumberOfWarningMessages: 'CANTIDAD DE MENSAJES DE ADVERTENCIA',
    ErrorType: 'TIPO DE ERROR',
    TabletUsageSummary: 'RESUMEN USO DE LA TABLETA',
    ErrorsDistribution: 'DISTRIBUCIÓN DE ERRORES',
    ClosingOperations: 'CIERRE DE OPERACIONES',
    Retraso: 'RETRASO',
    EstimatedDeparture: 'ESTIMADO DE ZARPE',
    HoursOperating: 'HORAS OPERANDO',
    Transferencia: 'TRANSFERENCIA',
    Fleet: 'FLOTA',
    NoWeighed: 'NO PESADO',
    BinEstXCulm: 'EST. GAMELA X CULM',
    EndOperationsQuestion: 'ESTÁ SEGURO DE FINALIZAR LAS OPERACIONES DE LA VISITA?',
    StartOperations: 'INICIO DE OPERACIONES',
    Berthed: 'ATRACADO',
    PlannedTon: 'TON - PLANIFICADA',
    CertifiedTon: 'TON - CERTIFICADA',
    WeighedTon: 'TON - PESADA',
    RemainingTon: 'TON - RESTANTE',
    CurrentSeal: 'PRECINTO ACTUAL',
    NewSeal: 'PRECINTO NUEVO',
    AssignedBy: 'ASIGNADO POR',
    MphAffectation: 'AFECTACIÓN MPH',
    InoperativeCrane: 'GRUA INOPERATIVA',
    FirstMovement: 'PRIMER MOVIMIENTO',
    RecordUpdatedSuccessFully: 'REGISTRO ACTUALIZADO CORRECTAMENTE',
    MultipleTime: 'TIEMPO MÚLTIPLE',
    TheDateFromIsRequired: "LA FECHA 'DESDE' ES REQUERIDO",
    TheDateToIsRequired: "LA FECHA 'HASTA' ES REQUERIDO",
    TimeIsRequired: 'EL TIEMPO ES REQUERIDO',
    RecordUpdatedSuccessFully: 'REGISTRO ACTUALIZADO CORRECTAMENTE',
    YouMustHaveAtLeastOneHoldPerCrane: 'SE DEBE DE TENER AL MENOS 1 BODEGA POR GRUA',
    HoursWorked: 'HORAS TRABAJADAS',
    PlannedWorked: 'HORAS PLANIFICADAS',
    CancelationReason: 'MOTIVO DE CANCELACION',
    DescriptionOfGoods: 'DICE CONTENER',
    Heading: 'RUBROS',
    UMReefer: 'U.M REFRIGERACIÓN',
    UMOversize: 'U.M SOBREDIMENDIONADA',
    BaysLot: 'POSICIÓN',
    MachineData: 'DATOS DE MAQUINA',
    EventList: 'LISTADO DE EVENTOS',
    Event: 'EVENTO',
    EventsPerYard: 'EVENTOS POR PATIO',
    classificationName: 'NOMBRE DE CLASIFICACIÓN',
    EquipmentDestination: 'EQUIPO DESTINO',
    DispachedLogistics: 'LOGISTICA DE DESPACHO',
    AssociateCargoType: 'ASOCIAR TIPO DE CARGA',
    AssociateEventsPerYard: 'ASOCIAR EVENTOS POR PATIO',
    Procedence: 'PROCEDENCIA',
    Continue: 'CONTINUAR',
    PlannedHoursCrane: 'HORAS PLANIFICADAS DE LA GRUA',
    MustIndicateVesselOrGraneToMphConfig: 'DEBE INDICAR BUQUE O GRUA PARA LA CONFIGURACIÓN DE MPH',
    Inoperative: 'INOPERATIVO',
    EirNumber: 'NUMERO DE EIR',
    Welcome: 'BIENVENIDO/A',
    Delivered: 'ENTREGADO',
    EquipmentInterchangeReceipt: 'RECIBO DE INTERCAMBIO DE EQUIPO',
    FullsSeal: "PRECINTO FULL",
    DamageCodes: 'CODIGOS DE DAÑOS',
    SealShippingLineOrCustomBroker: 'PRECINTOS LINEA O AGENTE',
    SealCustomBroker: 'PRECINTO AGENTE ADUANAL',
    CustomBroker: 'AGENTE ADUANAL',
    PleaseReturnEmptyEquipmentAt: 'FAVOR DEVOLVER EL EQUIPO VACIO EN',
    ReeferEquipmet: 'EQUIPOS REFRIGERADOS',
    CarrierData: 'DATOS DEL TANSPORTE',
    CheckerData: 'DATOS DEL CHEQUEADOR',
    DriversReadableName: 'NOMBRE LEGIBLE DEL CHOFER',
    ReadableName: 'NOMBRE LEGIBLE',
    KeyBoard: 'TECLADO',
    Tarpaulin: 'LONA',
    PackagingBlInformation: "INFORMACIÓN EMBALAJE/BL",
    //vilchez
    navigation:'NAVEGACIÓN',
    SimultaneousOpers: 'OPERS. SIMULTÁNEAS',
    OrdersNotWeighed: 'ORDENES NO PESADAS',
    InactiveModule:'MODULO PADRE INACTIVO, NO SE PUEDE EDITAR, NI REGISTRAR UN NUEVO SUDMODULO',
    ModuleName:'NOMBRE MODULO',
    SubModuleName:'NOMBRE SUBMODULO',
    SubModule:'SUBMODULO',
    VersionCode: 'CODIGO VERSIÓN',
    VersionControlList:'LISTADO DE CONTROL DE VERSIONES',
    AvailableDate:'FECHA DISPONIBLE',
    ExpirationDate: 'FECHA VENCIMIENTO',
    UpdateDeadLine:'FECHA LIMITE ACTULIZACIÓN',
    MandatoryDownload:'DESCARGA OBLIGATORIA',
    downloadApk:'DESCARGAR APK',
    ErrorExpirationDateRangeAvalible:' LA FECHA VENCIMIENTO DEBE SER MAYOR A FECHA DISPONIBLE',
    ErrorExpirationDateRangeDeadline:'LA FECHA VENCIMIENTO DEBE SER MAYOR A FECHA LIMITE ACTUALIZACIÓN',
    ErrorDeadlineDateRangeAvailable:'LA FECHA LIMITE ACTUALIZACIÓN DEBE SER MAYOR A FECHA DISPONIBLE',
    ErrorDescription:'INGRESE DESCRIPCIÓN',
    userAssignedRoles: 'ROLES ASIGNADOS DEL USUARIO',
    areaType: 'TIPO DE ÁREA',
    groupingOfYards:'AGRUPACIÓN DE PATIOS',
    yardGroup:'GRUPO DE PATIO',
    height_:'ALTURA',
    bulkCargoName: 'CARGA GRANEL',
    generalCargo: 'CARGA GENERAL',
    areaTypeList: 'LISTADO DE TIPO DE ÁREA',
    access_: 'ACCESO',
    accessCode: 'CODIGO DE ACCESO',
    areaTypeName: 'NOMBRE DE TIPO DE ÁREA',
    viewRelatedYards: 'VER PATIOS RELACIONADOS',
    block: 'BLOQUE',
    bays: 'BAHÍAS',
    stack: 'PILAS',
    blockStatus: 'ESTATUS DEL BLOQUE',
    blStatus:"ESTATUS BL",
    blockName: 'NOMBRE DEL BLOQUE',
    heightPlan: 'PLANO DE ALTURA',
    dateUpdate: 'FECHA ACTULIZACIÓN',
    rolesbyVesselType: 'ROLES POR TIPO DE BUQUE',
    notApply: 'NO APLICA',
    viewDocuments:'VER DOCUMENTOS',
    machineClassification: 'CLASIFICACIÓN DE MAQUINA',
    classificationMachine: 'CLASIFICACIÓN MAQUINA',
    subsystemType: 'TIPO DE SUBSISTEMA',
    srcOption:'OPCION SRC',
    fatherError:'El padre que usted selecciono es incorrecto vuelva a seleccionar otro',
    exidedOrder:'La orden es muy exagerada le recomendamos un orden menor',
    ordenExists:'Ya existe esta orden',
    ordenReselectOrder:'Error volver a reelegir el orden',
    exampleLocation: 'EJEMPLO/EJEMPLO.VUE',
    exampleRoute: '/EJEMPLO',
    menu:'MENU',
    userAssignedRoles: 'ROLES ASIGNADOS DEL USUARIO',
    role:'ROL',
    birthDate:'FECHA DE CUMPLEAÑOS',
    incidenceGroupName: 'NOMBRE DE GRUPO DE INCIDENCIA',
    userData:'DATOS DEL USUARIO',
    hideTableRolesPass: 'OCULTAR TABLA DE ROLES',
    viewTableRolespass: 'VER TABLA DE ROLES',
    availableRoles: 'ROLES DISPONIBLES',
    anulationWeighing: 'ANULACIÓN DE PESAJES',
    blDocuments: 'DOCUMENTOS DEL BL',
    blType: 'TIPO DE BL',
    blCargoMaster: 'BL MASTER',
    unNumberList: 'LISTA DE UN NUMBER',
    plan: 'PLANO',
    dateRangefilterContainer: 'DEBE INGRESAR RANGO DE FECHAS AL FILTRAR POR CONTENEDOR',
    eventReasonList: 'LISTADO DE MOTIVO DE EVENTOS',
    eirType: 'TIPO DE EIR',
    eventForReason: 'MOTIVO DE EVENTO',
    cancellationDate: 'FECHA DE CANCELACIÓN',
    receptionToShip: 'RECEPCIÓN A BUQUE',
    returned: 'RETORNO',
    previousPosition: 'POSICIÓN ANTERIOR',
    previousModule: 'MÓDULO ANTERIOR',
    newPosition: 'NUEVA POSICIÓN',
    InspectionsList: 'LISTADO DE INSPECCIONES',
    eventGeneralList: 'LISTADO GENERAL DE EVENTOS',
    inspectionGeneralList: 'LISTADO GENERAL DE INSPECCIONES',
    equipmetToPosition: 'EQUIPOS POR POSICIONAR',
    eirIssuedList: 'LISTADO DE EIR EMITIDOS',
    deliverySector: 'SECTOR DE ENTREGA',
    internal: 'INTERNO',
    external: 'EXTERNO',
    dimension: 'DIMENSIÓN',
    hsCode: 'CODIGO HS',
    associateCommodity: 'ASOCIAR MERCANCIA',
    associateGeneralGroupCargoCommodity: ' ASOCIAR MERCANCIA GRUPO GENERAL',
    dischargeConfirmation: 'CONFIRMACIÓN DE DESEMBARQUE',
    remainder: 'REMANENTE',
    firstEvent: 'PRIMER EVENTO',
    dateFierstEvent:"F. PRIMER EVENTO",
    LastEvent:"ULTIMO EVENTO",
    DateLastEvent:"F. ULTIMO EVENTO",
    daysInPort:"DÍAS EN PUERTO",
    stowageStatus:"ESTATUS ESTIBA",
    statusYardWarehouse:"ESTATUS EN PATIO / ALMACÉN",
    blsinyard:"BLS EN PATIO",
    blsPendingReceive:"BLS PENDIENTES POR RECEPCIONAR",
    blsHousePendingReceive:"BLS HOUSE PENDIENTES POR RECEPCIONAR",
    pendingReceive:"PENDIENTE POR RECEPCIONAR",
    pendingDispatch:"PENDIENTE POR DESPACHAR",
    maxLengthDeclared: 'ESTE VALOR SUPERA AL DECLARADO',
    IncorrectVolumenValue: 'ESTE VALOR NO CONCUERDA CON VOLUMEN EN SERIALIZACIÓN',
    IncorrectVolumenWeightValue: 'ESTE VALOR NO CONCUERDA CON PESO EN SERIALIZACIÓN',
    PendingReceiveInYard:"PENDIENTE POR RECEPCIONAR EN PATIO",
    equipmentConfirmed: 'ESTE EQUIPO YA FUE CONFIRMADO',
    dischargeQuantity: 'CANTIDAD DESEMBARCADA',
    discharged: 'DESEMBARCADO',
    plannedQuantity: 'CANTIDAD PLANIFICADA',
    consigneeAssignment: 'ASIGNACIÓN DE CONSIGNATARIO',
    identifiedQuantity: 'CANTIDAD IDENTIFICADA',
    landedQuantity: 'CANTIDAD DESMBARCADA',
    quantityRemainError: 'LA CANTIDAD ES MAYOR AL REMANENTE',
    quantityRemainErrorBl: 'LA CANTIDAD ES MAYOR AL REMANENTE DEL BL',
    packagingInformation: 'INFORMACIÓN DEL EMBALAJE',
    blInformation: 'INFORMACIÓN DEL BL',
    pendingToDischarge: 'PENDIENTE POR DESCARGAR',
    pendingToIndentify: 'PENDIENTE POR IDENTIFICAR',
    pendingToShip: 'PENDIENTE POR EMBARCAR',
    assignExporter: 'ASIGNACIÓN DE EXPORTADOR',
    loadConfirmation: 'CONFIRMACIÓN DE CARGA',
    lapseDetail: 'DETALLE POR CORTE',
    lapseSummary: 'RESUMEN POR CORTE',
    //GENERALES 
    //-----------------------------------------------------------------------------------------------------------
    equipmentDoesNotHaveDeperturePosition:'EQUIPO NO POSEE POSICIÓN DE SALIDA',
    equipmentDoesNotArrive: 'EQUIPO NO POSEE POSICIÓN DE ARRIBO',
    transactionHistoryOfContainer :'HISTORIAL DE TRANSACCIONES DEL CONTENEDOR',
    positionContainerSlot: 'POSICIONAR CONTENEDOR',
    auxiliaryZone: 'ÁREA AUXILIAR',
    positionedContainer: 'EQUIPOS POSICIONADOS',
    notValidMovemenet: 'MOVIMIENTOS NO VALIDOS',
    auxiliaryArea: 'ÁREA AUXILIAR',
    youMustChooseABlockAndBay: 'DEBE ELEGIR UN BLOQUE Y AL MENOS UN BAY',
    massivePositioning: 'POSICIONAMIENTO MASIVO',
    aSelectedVisitIsRequired: 'SE REQUIERE TENER UNA VISITA O GAMELA SELECCIONADA',
    maximunFourSeals:'MAXIMO CUATRO PRECINTOS',
    minimunValue10:'VALOR MINIMO 10,00',
    statusBlock:'STATUS BLOQUE',
    habilited:'HABILITADO',
    assignconditions: 'ASIGNAR CONDICIONES',
    generatoPlan:'GENERAR PLANO',
    specialContainer:'EQUIPO ESPECIAL',
    exceededArea: 'EL AREA EXCEDE LOS 50.000,00 M²',
    teusCapacity: 'CAPACIDAD DE TEUS',
    eirPendingTobeIssuedList: 'LISTADO DE EIR PENDIENTES POR EMITIR',
    equipmentType: 'TIPO DE EQUIPO',
    viewEir: 'VER EIR',
    noImages: 'SIN IMÁGENES',
    editYardConfiguration: 'EDITAR CONFIGURACIÓN DE PATIO',
    newYardConfiguration: 'NUEVA CONFIGURACIÓN DE PATIO',
    isItOutOfTime: '¿ES A DESTIEMPO?',
    currentPosition: 'POSICIONAMIENTO ACTUAL',
    errorDatabasicBl: 'EXISTEN ERRORES EN LA PESTAÑA DE DATOS BÁSICOS',
    errorConsigneeBl: 'EXISTEN ERRORES EN LA PESTAÑA DE CONSIGNATARIO',
    errorDescriptionOfGoodsBl: 'EXISTEN ERRORES EN LA PESTAÑA DE DICE CONTENER',
    government: 'GOBIERNO',
    private: 'PRIVADA',
    customsRegimeList: 'LISTADO DE REGIMEN ADUANAL',
    accessTypeList: 'LISTADO DE TIPOS DE ACCESOS',
    consigneeName: 'NOMBRE DEL CONSIGNATARIO',
    consigneeRepresentativeName: 'NOMBRE REPRESENTANTE DEL CONSIGNATARIO',
    consigneeAddress: 'DIRECCIÓN DEL CONSIGNATARIO',
    classes: 'CLASES',
    customsRegimeName: 'NOMBRE DE REGIMEN ADUANAL',
    accessTypeName: 'NOMBRE DE TIPO DE ACCESO',
    specialEquipments: 'EQUIPOS ESPECIALES',
    emptyReturn: 'DEVOLUCIÓN VACIO',
    wire: 'CABLE',
    Tarpaulin: 'LONA',
    TarpaulinCondition: 'ESTADO LONA',
    specials: 'ESPECIALES',
    Delivered: 'ENTREGADO',
    EquipmentInterchangeReceipt: 'RECIBO DE INTERCAMBIO DE EQUIPO',
    FullsSeal: "PRECINTO FULL",
    DamageCodes: 'CODIGOS DE DAÑOS',
    SealShippingLineOrCustomBroker: 'PRECINTOS LINEA O AGENTE',
    SealCustomBroker: 'PRECINTO AGENTE ADUANAL',
    CustomBroker: 'AGENTE ADUANAL',
    PleaseReturnEmptyEquipmentAt: 'FAVOR DEVOLVER EL EQUIPO VACIO EN',
    ReeferEquipmet: 'EQUIPOS REFRIGERADOS',
    CarrierData: 'DATOS DEL TANSPORTE',
    CheckerData: 'DATOS DEL CHEQUEADOR',
    DriversReadableName: 'NOMBRE LEGIBLE DEL CHOFER',
    ReadableName: 'NOMBRE LEGIBLE',
    KeyBoard: 'TECLADO',
    CurrentArea: 'AREA ACTUAL',
    listOfAlerts : 'LISTA DE ALERTAS',
    plan: 'PLANO',
    returnToReceptionList: 'VOLVER A LA LISTA DE RECEPCIÓN',
    whatActionWantPerformContainer: '¿QUE ACCIÓN DESEA REALIZAR CON EL CONTENEDOR',
    position: 'POSICIONAR',
    positioningList:'LISTA DE POSICIONAMIENTO',
    containerNotPositioned:"CONTENEDOR NO POSICIONADO",
    listContainersNotPositioned:'LISTA DE CONTENEDORES NO POSICIONADOS',
    notPositioned:'NO POSICIONADOS',
    minimunValue10:'VALOR MINIMO 10,00',
    statusBlock:'STATUS BLOQUE',
    habilited:'HABILITADO',
    assignconditions: 'ASIGNAR CONDICIONES',
    generatoPlan:'GENERAR PLANO',
    specialContainer:'EQUIPO ESPECIAL',
    containerNotFount:'CONTENEDOR NO ENCONTRADO',
    itTheConsolidator: '¿ES EL CONSOLIDADOR?',
    nEir: 'N° DE EIR',
    nTicketOut : 'NRO PASE DE SALIDA',
    ticketOut: 'PASE DE SALIDA',
    eirOfOut: 'EIR DE SALIDA',
    nroEirSiget:'NRO EIR SIGET',
    technicalAspects: 'ASPECTOS TÉCNICOS',
    actualStatus:'ESTATUS ACTUAL',
    receptionDocument: 'ACTA DE RECEPCIÓN',
    isThisTheConsolidator: '¿ES EL CONSOLIDADOR?',
    newSerial: '¿NUEVO SERIAL?',
    consolidator:'CONSOLIDADOR',
    destinationContainer: 'EQUIPO DESTINO',
    bolipuertoReceptionRecordNro:'NRO ACTA RECEPCIÓN BOLIPUERTOS',
    customsRegime: 'REGIMEN ADUANAL',
    nDua: 'N° DUA',
    nBooking: 'Nº BOOKING',
    shippingLineCode: 'CÓDIGO LINEA NAVIERA',
    containerType: 'TIPO DE CONTENEDOR',
    pluggedIn:'CONECTADO',
    plugged:'CONECTADO',
    ContainerPositioning:'POSICIONAMIENTO DEL CONTENEDOR',
    newContainerReception: 'NUEVA RECEPCIÓN DEL CONTENEDOR',
    internalTransfer:"TRASLADO INTERNO",
    externalWarehouse:"ALMACÉN EXTERNO",
    receiptList:"LISTA DE RECEPCIÓN",
    emptying: "VACIADO",
    fill:"LLENADO",
    BlReception: 'BL RECEPCIÓN',
    newBlReception: 'NUEVA RECEPCIÓN DEL BL',
    newBlHouseReception: 'NUEVA RECEPCIÓN DEL BL HOUSE',
    editContainerReception: 'EDITAR RECEPCIÓN DEL CONTENEDOR',
    editBlReception: 'EDITAR RECEPCIÓN DEL BL',
    editBlHouseReception: 'EDITAR RECEPCIÓN DEL BL HOUSE',
    ContainerDispatch: 'DESPACHO DEL CONTENEDOR',
    containerPositioning: 'POSICIONAMIENTO',
    editContainerPositioning: 'EDITAR POSICIONAMIENTO',
    events: 'EVENTOS',
    event: 'EVENTO',
    newEvent: 'NUEVO EVENTO',
    eventDate:'FECHA EVENTO',
    lastMovementDate:'FECHA ULTIMO MOVIMIENTO',
    documentation: 'DOCUMENTACIÓN',
    currentSituationYard:'SITUACIÓN ACTUAL',
    yardGeneralState:'ESTADO GENERAL DE PATIO',
    inventoryMassiveLoad:'CARGA MASIVA DE INVENTARIO',
    receptionInYard:'RECEPCIÓN EN PATIO',
    DaysInCountry:'DÍAS EN EL PAIS',
    plannedPosition:'POSICIÓN PLANIFICADA',
    cancelationDate:'FECHA CANCELACIÓN',
    shipmentConfirmationDate:'FECHA CONFIRMACIÓN EMBARQUE',
    dispatchList:'LISTA DE DESPACHO',
    inventoryList: 'LISTA DE INVENTARIO',
    massiveListOfEquipmentReception:'LISTA MASIVA DE EQUIPOS PENDIENTES POR RECEPCIONAR',
    massiveListEquipmentDispatch:'LISTA MASIVA DE EQUIPOS PENDIENTES POR DESPACHAR',
    dinamicReport:'REPORTES DINÁMICO',
    perEquipment:'POR EQUIPO',
    dispatchContainer:'DESPACHAR',
    ReceptionsVisit:"RECEPCIONES VISITA",
    PendingReceptions: 'RECEPCIONES PENDIENTES',
    masive:'MASIVO',
    reception:'RECEPCIÓN',
    dispatchs:'DESPACHOS',
    dispatch:'DESPACHO',
    alpha: 'ALFA',
    yardList:'LISTA DE PATIOS',
    teus: 'TEUS',
    occupiedTeus:'TEUS OCUPADOS',
    availableTeus:'TEUS DISPONIBLES',
    lastEntry:'ÚLTIMO INGRESO',
    lastDespatch:'ÚLTIMO DESPACHO',
    yardType: 'TIPO DE PATIO',
    block:'BLOQUE',
    visit:'VISITA',
    atd:'ATD',
    receptionEirNo:'NRO EIR RECEPCIÓN',
    originVisit:'VISITA ORIGEN',
    customBroker:'AGENTE ADUANAL',
    booking: 'BOOKING',
    floorPlan:'PLANO PLANTA',
    planimetry:'PLANIMETRIA',
    locateEquipment:'UBICAR EQUIPO',
    todayEntry:'HOY ENTRADA',
    todayExit:'HOY SALIDA',
    assignedPosition:'POSICIÓN ASIGNADA',
    assignedModule:'MÓDULO ASIGNADO',
    govermentPrivate:"GOBIERNO / PRIVADO",
    originPosition:'POSICIÓN DE ORIGEN',
    daysInYard:"DÍAS EN PATIO",
    nticket: 'N° DE PASE',
    nEir: 'N° DE EIR',
    dua: 'DUA',
    sidunea:'SIDUNEA',
    dispatchDate: 'FECHA DE DESPACHO',
    dateLastOperation: 'FECHA ULTIMA OPERACIÓN',
    nTicketOut : 'NRO PASE DE SALIDA',
    eirOfOut: 'EIR DE SALIDA',
    nroEirBolipuertos:'NRO EIR BOLIPUERTOS',
    nroEirSiget:'NRO EIR SIGET',
    technicalAspects: 'ASPECTOS TÉCNICOS',
    sequence: 'SECUENCIA',
    activity: 'ACTIVIDAD',
    initials: 'SIGLAS',
    typecargocode: 'SIGLAS',
    acronym: 'SIGLAS',
    required: 'Campo requerido ',
    onlyText: 'Solo Texto',
    onlyNumber: 'Solo Números',
    onlyAlphanumeric: 'Solo Alfanuméricos',
    onlyCoordinate: 'Solo Coordenadas',
    onlyDecimal: 'Solo Decimales',
    onlyUppercase: 'SOLO MAYÚSCULAS.',
    integer: 'solo enteros',
    minValueIs: 'El valor minimo es',
    maxValueIs: 'El valor máximo es',
    metrics: 'SOLO MÉTRICAS',
    number: 'NÚMERO',
    filter: 'FILTRAR',
    filtro: 'FILTRO',
    file: 'ARCHIVO',
    lang: 'ESPAÑOL',
    yard: 'UBICACIÓN',
    toSelect: 'SELECCIONAR ',
    select: 'SELECCIONE ',
    selected: 'SELECCIONADOS ',
    selectedLogo: 'LOGO SELECCIONADO',
    option: 'OPCIÓN ',
    load: 'CARGANDO',
    process: "PROCESO",
    success: "EXITO",
    error: "ERROR",
    changePass: 'Cambiar Contraseña',
    moduleInfo: 'Información del módulo',
    nueva: 'NUEVA ',
    nuevo: 'NUEVO ',
    modelBrand: 'MARCA Y MODELO',
    modelName: 'NOMBRE DEL MODELO',
    model: 'MODELO',
    brand: 'MARCA',
    brandName: 'NOMBRE DE LA MARCA',
    brands: 'MARCAS',
    name: 'NOMBRE',
    lastName: 'APELLIDO',
    registerName: 'INGRESE EL NOMBRE',
    register: 'REGISTRAR ',
    clearFields: 'LIMPIAR CAMPOS',
    cleanInformation: 'LIMPIAR INFORMACIÓN',
    add: 'AGREGAR ',
    edit: 'EDITAR ',
    delete: 'ELIMINAR ',
    Romeve: 'ELIMINAR',
    badImage: 'REQUIERE UNA IMAGEN VALIDA',
    needValidImage: 'NECESITO UNA IMAGEN VALIDA',
    picture: 'FOTO',
    crane: 'GRÚA',
    type: 'TIPO',
    list: 'LISTA',
    work: 'TRABAJO',
    movements: 'MOVIMIENTOS',
    movement: 'MOVIMIENTO',
    supplies: 'INSUMOS',
    Supplies: 'INSUMO',
    supplie: 'INSUMO',
    supply: 'INSUMO',
    turn: 'TURNO',
    stowage: 'ESTIBA',
    vessel: 'BUQUE',
    role: 'ROL',
    must: 'DEBE ',
    transaction: 'TRANSACCIÓN',
    FINALIZADO: 'FINALIZADO',
    CANCELADO: 'CANCELADO',
    ACTIVO: 'ACTIVO',
    INACTIVO: 'INACTIVO',
    USUARIO: 'USUARIO',
    FECHA: 'FECHA',
    group: 'GRUPO',
    min: 'CAMPO REQUIERE MÍNIMO ',
    max: 'CAMPO REQUIERE MÁXIMO ',
    chars: ' CARACTERES',
    change: 'CAMBIAR ',
    code: 'CÓDIGO',
    open: 'ABRIR',
    close: 'CERRAR',
    watch: 'VER',
    hold: 'BODEGA',
    from: 'DESDE',
    to: 'HASTA',
    preselected: 'PRESELECCIONADO',
    holdAssigned: 'ASIGNADO A UNA BODEGA',
    country: 'PAIS',
    documents: 'DOCUMENTOS',
    documentation: 'DOCUMENTACIÓN',
    invalid: 'Campo Invalido. ',
    capacity: 'CAPACIDAD',
    grain: ' GRANO ',
    bail: ' BALA ',
    deck: 'CUBIERTA',
    finish: 'FINALIZAR',
    operative: 'OPERATIVA',
    operational: 'OPERATIVO',
    operativa: "OPERATIVA",
    range: 'ALCANCE',
    description: 'DESCRIPCIÓN',
    return: 'REGRESAR',
    port: 'PUERTO',
    location: 'UBICACIÓN',
    localizacion: "LOCALIZACIÓN",
    state: 'ESTADO',
    latitude: 'LATITUD',
    longitude: 'LONGITUD',
    berthName: 'NOMBRE DEL MUELLE',
    condition: 'CONDICIÓN ',
    conditions: 'CONDICIONES ',
    draft: 'CALADO',
    mooring: 'BITA',
    service: 'SERVICIO',
    serviceList: 'LISTADO DE SERVICIOS',
    serviceCode: 'CODIGO SERVICIO',
    itinerary: 'ITINERARIO',
    line: 'LINEA',
    see: ' VER ',
    exit: 'SALIR',
    all: 'TODOS',
    ton: 'TONELADA',
    affiliate: 'ASOCIAR ',
    frecuency: 'FRECUENCIA',
    reset: 'REINICIAR ',
    table: 'TABLA ',
    observation: 'OBSERVACIÓN',
    voyage: 'VIAJE',
    voyageNumber: 'NRO. VIAJE',
    company: 'EMPRESA',
    branch: 'SUCURSAL',
    object: 'OBJETO',
    municipality: 'MUNICIPIO',
    parish: 'PARROQUIA',
    address: 'DIRECCIÓN',
    client: 'CLIENTE',
    contact: 'CONTACTO',
    save: 'GUARDAR CAMBIOS',
    level: 'NIVEL',
    module: 'MODULO',
    occupiedModules: 'MODULOS OCUPADOS',
    availableModules: 'MODULOS DISPONIBLES',
    unitsStorage: 'UNIDADES EN ALMACÉN',
    order: 'ORDEN',
    view: 'VISTA',
    viewName: 'NOMBRE VISTA',
    toView: 'VER',
    preview: 'PREVISUALIZAR',
    permission: 'PERMISOS',
    assign: 'ASIGNAR ',
    supplier: 'PROVEEDOR',
    quantity: 'CANTIDAD',
    representative: 'REPRESENTANTE',
    resource: 'RECURSO',
    resources: 'RECURSOS',
    or: 'o',
    status: 'ESTATUS',
    applies: 'APLICAR',
    Applies: 'APLICA',
    AppliesTo: 'APLICA',
    apply: 'APLICA',
    IMO: 'IMO',
    yes: 'SI',
    no: 'NO',
    accesory: 'ACCESORIO',
    accesories: 'ACCESORIOS',
    component: 'COMPONENTE',
    components: 'COMPONENTES',
    standar: 'ÉSTANDAR',
    january: 'ENERO',
    february: 'FEBRERO',
    march: 'MARZO',
    april: 'ABRIL',
    may: 'MAYO',
    june: 'JUNIO',
    july: 'JULIO',
    august: 'AGOSTO',
    september: 'SEPTIEMBRE',
    octuber: 'OCTUBRE',
    november: 'NOVIEMBRE',
    december: 'DICIEMBRE',
    weight: 'PESO',
    density: 'DENSIDAD',
    classification: 'CLASIFICACIÓN',
    classifications: 'CLASIFICACIONES',
    part: 'ELEMENTO',
    parts: 'ELEMENTOS',
    general: 'GENERAL',
    loading: 'CARGANDO',
    planning: 'PLANIFICACÍON',
    enter: 'INGRESE',
    machine: 'MÁQUINA',
    machinery: 'MAQUINARIA',
    machineries: 'MAQUINARIAS',
    ubication: 'UBICACIÓN',
    year: 'AÑO',
    and: 'Y',
    Amount: 'CANTIDAD',
    IdNumber: 'CEDULA',
    lessThan: 'MENOR A',
    greaterThan: 'MAYOR A',
    size: 'TAMAÑO',
    sizes: 'TAMAÑOS',
    back: 'ATRÁS',
    special: 'ESPECIAL',
    element: 'ELEMENTO',
    volume: 'VOLUMEN',
    LOAD: 'EMBARQUE',
    DISCHARGE: 'DESEMBARQUE',
    entity: 'ENTIDAD',
    FULLLOADLIST: 'LISTA DE CARGA FULL',
    EMPTYLOADLIST: 'LISTA DE CARGA VACÍA',
    damageTypeAcronym: 'SIGLAS DE TIPO DE DAÑO',
    StateAcronyms: 'SIGLAS DE ESTADO',
    Sender: 'REMITENTE',
    Recipient: 'DESTINATARIO',
    repairs: 'REPARACIONES',
    TimeOfPreparation: 'TIEMPO DE PREPARACIÓN',
    ControlReference: 'REFERENCIA DE CONTROL',
    CommunicationAgreement: 'ACUERDO DE COMUNICACIÓN',
    MessageReferenceNumber: 'NUMERO REFERENCIAL DEL MENSAJE',
    MessageTypeIdentifier: 'IDENTIFICADOR DEL TIPO DE MENSAJE',
    MessageTypeVersionNo: 'NRO. DE VERSIÓN DEL TIPO DE MENSAJE',
    MessageTypeReleaseNumber: 'NRO. DE LIBERACIÓN DEL TIPO DE MENSAJE',
    ControllingAgency: 'ORGANISMO DE CONTROL',
    AssociationAssignedCode: 'CODIGO ASIGNADO A LA ASOCIACIÓN',
    DocumentNumber: 'NUMERO DEL DOCUMENTO',
    MessageType: 'TIPO DE MENSAJE',
    MessageCompilationTime: 'TIEMPO DE COMPILACIÓN DEL MENSAJE',
    DischargeVoyageNumber: 'NUMERO DEL VIAJE DE DESCARGA',
    PlaceOfDeparture: 'LUGAR DE SALIDA',
    EstimatedTimeOfDeparture: 'TIEMPO ESTIMADO DE SALIDA',
    NextPortOfCall: 'PROXIMO PUERTO DE ESCALA',
    ActualTimeOfArrival: 'TIEMPO REAL DE LLEGADA',
    ACTUAL_TIME_DEPARTURE: 'TIEMPO REAL DE SALIDA',
    ESTIMATED_TIME_ARRIVAL:'TIEMPO ESTIMADO DE LLEGADA',  
    CarrierCode: 'CODIGO DEL TRANSPORTADOR',
    STANDARD_DOCUMENTATION: 'DOCUMENTACIÓN ESTÁNDAR',
    VesselCallSign: 'SEÑAL DE LLAMADA DEL BUQUE',
    VesselLloydsCode: 'CODIGO LLOYD DEL BUQUE',
    IdMeanfTransportIdentification: 'ID DEL MEDIO DE IDENTIFICACIÓN DEL TRANSPORTE',
    VesselName: 'NOMBRE DEL BUQUE',
    LoadingVoyageNumber: 'NUMERO DEL VIAJE DE CARGA',
    Containers: 'CONTENEDORES',
    InspectionsDone: 'INSPECCIONES REALIZADAS',
    ListInspectionsDone: 'LISTA DE INSPECCIONES REALIZADAS',    
    TransactionDate: 'FECHA DE TRANSACCIÓN',
    damageReport: 'REPORTE DE DAÑOS',
    ESTIMATEDTIMEOFARRIVAL: 'TIEMPO ESTIMADO DE LLEGADA',
    StowageFactor: 'FACTOR DE ESTIBA',
    DraftListing: 'LISTADO DE CALADO',
    TotalCertification: 'CERTIFICACIÓN TOTAL',
    HoldList: 'LISTA DE BODEGAS',
    CertifiedCapacity: 'CAPACIDAD CERTIFICADA',
    Deadweight: 'PESO MUERTO',
    PermissibleLoanOn: 'CAPACIDAD DE CARGA',
    Weighing: 'PESAJE',
    Taraweight: 'PESO TARA',
    transportDriver: 'TRANSPORTE-CHOFER',
    FullWeight: 'PESO FULL',
    DraftInspection: 'INSPECCIÓN DE CALADO',
    errorHoldPleaseCheck: 'SE REQUIERE PESO AL MENOS EN UNA BODEGA',
    MachineInformation: 'INFORMACIÓN DE LA MÁQUINA',
  },
  agridTable: {
    // Set Filter
    selectAll: 'Seleccionar todo',
    selectAllSearchResults: 'Seleccionar todos los resultados de búsqueda',
    searchOoo: 'Buscar...',
    blanks: 'Vacíos',
    noMatches: 'Sin coincidencias',

    // Number Filter & Text Filter
    filterOoo: 'Filtro...',
    equals: 'Igual',
    notEqual: 'Diferente',
    empty: 'ELIGE UNO',

    // Number Filter
    lessThan: 'Menor que',
    greaterThan: 'Mayor que',
    lessThanOrEqual: 'Menor o igual que',
    greaterThanOrEqual: 'Mayor o igual que',
    inRange: 'En el rango',
    inRangeStart: 'Desde',
    inRangeEnd: 'Hasta',

    // Text Filter
    contains: 'Contiene',
    notContains: 'No contiene',
    startsWith: 'Empieza con',
    endsWith: 'Termina con',

    // Date Filter
    dateFormatOoo: 'dd-mm-Yyyy',

    // Filter Conditions
    andCondition: 'Y',
    orCondition: 'O',

    // Filter Buttons
    applyFilter: 'Aplicar',
    resetFilter: 'Reiniciar',
    clearFilter: 'Borrar',
    cancelFilter: 'Cancelar',

    // Filter Titles
    textFilter: 'FILTRO DE TEXTO',
    numberFilter: 'FILTRO DE NUMEROS',
    dateFilter: 'FILTRO DE FECHAS',
    setFilter: 'ESTABLECER FILTRO',

    // Side Bar
    columns: 'Columnas',
    filters: 'Filtros',

    // columns tool panel
    pivotMode: 'Modo Pivote',
    groups: 'Grupos de columnas',
    rowGroupColumnsEmptyMessage: 'Soltar aquí para crear un grupo de columnas',
    values: 'Valores',
    valueColumnsEmptyMessage: 'Soltar aquí para agregar',
    pivots: 'Column Labels',
    pivotColumnsEmptyMessage: 'Soltar aquí para indicar etiquetas de columna',

    // Header of the Default Group Column
    group: 'Grupo',

    // Other
    loadingOoo: 'Cargando...',
    noRowsToShow: 'No hay filas para mostrar',
    enabled: 'Activado',

    // Menu
    pinColumn: 'Fijar Columna',
    pinLeft: 'Fijar a Izquierda',
    pinRight: 'Fijar a Derecha',
    noPin: 'Soltar',
    valueAggregation: 'Agregación por valor',
    autosizeThiscolumn: 'Tamaño automático para esta Columna',
    autosizeAllColumns: 'Tamaño automático para todas las Columnas',
    groupBy: 'Agrupar por',
    ungroupBy: 'Desagrupar por',
    resetColumns: 'Reiniciar Columnas',
    expandAll: 'Expandir Todo',
    collapseAll: 'Cerrar Todo',
    copy: 'Copiar',
    ctrlC: 'Ctrl+C',
    copyWithHeaders: 'Copiar con Cabeceras',
    paste: 'Pegar',
    ctrlV: 'Ctrl+V',
    export: 'Exportar',
    csvExport: 'Exportar a CSV',
    excelExport: 'Exportar a Excel (.xlsx)',
    excelXmlExport: 'Exportar a Excel antiguo (.xml)',

    // Enterprise Menu Aggregation and Status Bar
    sum: 'Sum',
    min: 'Min',
    max: 'Max',
    none: 'Nada',
    count: 'Cuenta',
    avg: 'Media',
    filteredRows: 'Filtrado',
    selectedRows: 'Seleccionado',
    totalRows: 'Total Filas',
    totalAndFilteredRows: 'Filas',
    page: 'Página',
    more: 'Más',
    to: 'A',
    of: 'De',
    next: 'Siguiente',
    last: 'Último',
    first: 'Primero',
    firstPage: 'Primera Página',
    previous: 'Anterior',
    previousPage: 'Pagina anterior',
    nextPage: 'SIGUIENTE PAGINA',
    lastPage: 'ULTIMA PAGINA', 

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: 'Gráfico de Pivote y Modo Pivote',
    pivotChart: 'Gráfico de Pivote',
    chartRange: 'Gráfico de Intervalo',

    columnChart: 'Columna',
    groupedColumn: 'Agrupado',
    stackedColumn: 'Apilado',
    normalizedColumn: '100% Apilado',

    barChart: 'Barra',
    groupedBar: 'Agrupado',
    stackedBar: 'Apilado',
    normalizedBar: '100% Apilado',

    pieChart: 'Tarta',
    pie: 'Tarta',
    doughnut: 'Donut',

    line: 'Línea',

    xyChart: 'X Y (Dispersión)',
    scatter: 'Dispersión',
    bubble: 'Burbuja',

    areaChart: 'Area',
    area: 'Área',
    stackedArea: 'Apilado',
    normalizedArea: '100% Apilado',

    histogramChart: 'Histograma',

    // Charts
    pivotChartTitle: 'Gráfico de Pivote',
    rangeChartTitle: 'Gráfico de Intervalo',
    settings: 'Configuración',
    data: 'Datos',
    format: 'Formato',
    categories: 'Categorías',
    defaultCategory: '(Ninguna)',
    series: 'Series',
    xyValues: 'Valores X Y',
    paired: 'Mode pareado',
    axis: 'Eje',
    navigator: 'Navegador',
    color: 'Color',
    thickness: 'Grosor',
    xType: 'Tipo X',
    automatic: 'Automático',
    category: 'Categoría',
    number: 'Número',
    time: 'Tiempo',
    xRotation: 'Rotación X',
    yRotation: 'Rotación Y',
    ticks: 'Ticks',
    width: 'Ancho',
    height: 'Alto',
    length: 'Longitud',
    padding: 'Relleno',
    spacing: 'Espaciado',
    chart: 'Gráfico',
    title: 'Título',
    titlePlaceholder: 'Título del Gráfico - doble clic para editar',
    background: 'Fondo',
    font: 'Fuente',
    top: 'Arriba',
    right: 'Derecha',
    bottom: 'Abajo',
    left: 'Izquierda',
    labels: 'Etiquetas',
    size: 'Tamaño',
    minSize: 'Tamaño mínimo',
    maxSize: 'Tamaño máximo',
    legend: 'Leyenda',
    position: 'Posición',
    markerSize: 'Ancho pincel',
    markerStroke: 'Trazo pincel',
    markerPadding: 'Relleno pincel',
    itemSpacing: 'Espaciado del ítem',
    itemPaddingX: 'Relleno X del ítem',
    itemPaddingY: 'Relleno Y del ítem',
    layoutHorizontalSpacing: 'Espaciado horizontal',
    layoutVerticalSpacing: 'Espaciado vertical',
    strokeWidth: 'Ancho del trazo',
    offset: 'Desplazamiento',
    offsets: 'Desplazamiento',
    tooltips: 'Tooltips',
    callout: 'Rótulo',
    markers: 'Pinceles',
    shadow: 'Sombra',
    blur: 'Difuminado',
    xOffset: 'Desplazamiento X',
    yOffset: 'Desplazamiento Y',
    lineWidth: 'Ancho de línea',
    normal: 'Normal',
    bold: 'Negrita',
    italic: 'Itálica',
    boldItalic: 'Negrita Itálica',
    predefined: 'Predefinido',
    fillOpacity: 'Opacidad de relleno',
    strokeOpacity: 'Opacidad de línea',
    histogramBinCount: 'Total papelera',
    columnGroup: 'Columna',
    barGroup: 'Barra',
    pieGroup: 'Tarta',
    lineGroup: 'Línea',
    scatterGroup: 'X Y (Dispersión)',
    areaGroup: 'Area',
    histogramGroup: 'Histograma',
    groupedColumnTooltip: 'Agrupado',
    stackedColumnTooltip: 'Apilado',
    normalizedColumnTooltip: '100% Apilado',
    groupedBarTooltip: 'Agrupado',
    stackedBarTooltip: 'Apilado',
    normalizedBarTooltip: '100% Apilado',
    pieTooltip: 'Tsrta',
    doughnutTooltip: 'Donut',
    lineTooltip: 'Línea',
    groupedAreaTooltip: 'Area',
    stackedAreaTooltip: 'Apilado',
    normalizedAreaTooltip: '100% Apilado',
    scatterTooltip: 'Dispersión',
    bubbleTooltip: 'Burbuja',
    histogramTooltip: 'Histograma',
    noDataToChart: 'No hay datos disponibles para hacer el gráfico.',
    pivotChartRequiresPivotMode: 'Gráfico de Pivote requiere activar el modo Pivote.',
    chartSettingsToolbarTooltip: 'Menu',
    chartLinkToolbarTooltip: 'Vinculado a la cuadricula', 
    chartUnlinkToolbarTooltip: 'Desvinculado de la cuadricula',
    chartDownloadToolbarTooltip: 'Descargar grafico',

    // ARIA
    ariaHidden: 'oculto',
    ariaVisible: 'visible',
    ariaChecked: 'chequeado',
    ariaUnchecked: 'no chequeado',
    ariaIndeterminate: 'indeterminado',
    ariaColumnSelectAll: 'Seleccionar todas las columnas',
    ariaInputEditor: 'Editor de entrada',
    ariaDateFilterInput: 'Entrada de filtro de fecha',
    ariaFilterInput: 'Entrada de filtro',
    ariaFilterColumnsInput: 'Entrada de columnas de filtro',
    ariaFilterValue: 'Filtrar valor',
    ariaFilterFromValue: 'Filtrar desde valor',
    ariaFilterToValue: 'Filtrar hasta valor',
    ariaFilteringOperator: 'Operador de filtrado',
    ariaColumnToggleVisibility: 'visibilidad de alternancia de columna',
    ariaColumnGroupToggleVisibility: 'visibilidad de alternancia del grupo de columnas',
    ariaRowSelect: 'Presione la barra espaciadora para seleccionar esta fila',
    ariaRowDeselect: 'Presione la barra espaciadora para anular la selección de esta fila',
    ariaRowToggleSelection: 'Presione la barra espaciadora para alternar la selección de filas',
    ariaRowSelectAll: 'Presione la barra espaciadora para alternar la seleccion de todas las filas',
    ariaSearch: 'Buscar',
    ariaSearchFilterValues: 'Valores del filtro de busqueda'
  },
  button: {
    accept: 'ACEPTAR',
    modify: 'MODIFICAR',
    cancel: 'CANCELAR',
    confirm: 'CONFIRMAR',
    exit: 'SALIR',
    preview: 'VISTA PREVIA',
    annul: 'ANULAR',
    return: 'REGRESAR',
  },
  buttonWizard: {
    Next: 'Siguiente',
    Back: 'Atrás',
    Finish: 'Finalizar',
  },
  status: {
    ACTIVO: "ACTIVO",
    INACTIVO: "INACTIVO"
  },
  validation:{ 
    quantityDispatchedExceedsSelected: "CANTIDAD A DESPACHAR SUPERA CANTIDAD SELECCIONADA",
    quantityDispatchedNotMatchSelected: "CANTIDAD A DESPACHAR NO CONCUERDA CON CANTIDAD SELECCIONADA",
    MissingInformationBl: 'SE REQUIRE COMPLETAR LA INFORMACION FALTANTE DEL BL MASTER',
    RequiredBl: 'SE REQUIRE AL MENOS UN BL',
    WeightAvailabilityPerDetail: 'EL PESO DEBE DE SER IGUAL A LO DISPONIBLE POR DETALLE',
    WeightAvailabilityPerPackaging: 'EL PESO DEBE DE SER IGUAL A LO DISPONIBLE POR EMBALAJE',
    QuantityAvailabilityPerDetail: 'LA CANTIDAD DEBE DE SER IGUAL A LO DISPONIBLE POR DETALLE',
    QuantityAvailabilityPerPackaging: 'LA CANTIDAD DEBE DE SER IGUAL A LO DISPONIBLE POR EMBALAJE',
    IndicatedQuantityDoesNotAgreeQuantitySerials: "CANTIDAD INDICADA NO CONCUERDA CON CANTIDAD DE SERIALES",
    NotDocumentype: 'ESTE TIPO DE DOCUMENTO NO CORRESPONDE A LA ACTIVIDAD DEL BL',
    RequiredDetail: 'SE REQUIRE AL MENOS UN DETALLE',
    notAvailableDetail: 'CANTIDAD DE TONELADAS A ASIGNAR NO DEBE SER MAYOR A LA DISPONIBILIDAD DEL DETALLE',
    notAvailablePackaging: 'CANTIDAD DE TONELADAS A ASIGNAR NO DEBE SER MAYOR A LA DISPONIBILIDAD DEL EMBALAJE',
    POLOrPODEqualCompanyBranchPort: 'EL POL O POD DEBE SER IGUAL AL PUERTO DE LA SUCURSAL DE LA EMPRESA',
    NotSameAsPLAndPOD: 'EL PUERTO DE CARGA Y DESCARGA NO PUEDE SER IGUAL',
    NotSameLoadPortAndDeliveryPort: 'EL PUERTO DE ORIGEN DE CARGA Y ENTREGA NO PUEDE SER IGUAL',
    mayoraCero: 'DEBE SER MAYOR QUE 0',
    onlyMayusTextEspecial: 'SOLO TEXTO Y CARACTERES , . - Ñ ´ /',
    UtcZone: "FORMATO REQUERIDO +00:00",
    onlyNumeroTresdigitos: "SOLO NÚMEROS DE 3 DIGITOS",
    not_siglas: "SIGLAS DEL CONTENEDOR NO CUMPLEN EL FORMATO DE VALIDACIÓN",
    notsameAsLoading: "Puerto de descarga no puede ser igual al de carga",
    notsameAsDischarge: "Puerto de carga no puede ser igual al de descarga",
    container_size: "El contenedor no puede superar el tamaño máximo de ",
    container_weight: "El contenedor no puede superar el peso máximo de ", 
    onlyCoordinate: 'Solo Coordenadas',
    onlyDecimal: 'Solo Decimales',
    onlyUppercase: 'SOLO MAYÚSCULAS.',
    needValidImage: 'NECESITO UNA IMAGEN VALIDA',
    badImage: 'IMAGEN NO VALIDA',
    min: 'CAMPO REQUIERE MÍNIMO ',
    max: 'CAMPO REQUIERE MÁXIMO ',
    minValueIs: 'El valor minimo es',
    maxValueIs: 'El valor maximo es',
    number: 'NUMERO',
    verifyFields: 'Verificar Campos',
    requiresUm: 'REQUIERE UNIDAD DE MEDIDA',
    chars: 'CARACTERES',
    alphaSpecials: 'SOLO TEXTO Y CARACTERES . - / $ & ()',
    email:'FORMATO REQUERIDO MAIL@EXAMPLE.COM',
    maxLength: 'CAMPO REQUIERE MAXIMO',
    minLength:'CAMPO REQUIERE MÍNIMO ',
    metrics: 'SOLO MÉTRICAS',
    onlyAlphanumeric: 'SOLO ALFANUMERICOS',
    onlyNumber: 'SOLO NUMEROS',
    onlyNumbers: 'SOLO NUMEROS',
    onlyText:'SOLO TEXTO',
    required: 'CAMPO REQUERIDO',
    integer:'SOLO ENTEROS',
    maxValue: 'EL VALOR MAXIMO ES',
    minValue: 'EL VALOR MINIMO ES',
    textareaSpecials: 'SOLO TEXTO Y CARACTERES , . * + - # / $ ! ()',    
    mobileNotReq: 'FORMATO REQUERIDO +584120000000, 0242-0000000 o 02420000000',
    emailToLower:'FORMATO REQUERIDO MAIL@EXAMPLE.COM',
    correctValue: 'CAMPO REQUERIDO',
    alpha: 'SOLO CARACTERES ALFABETICOS',
    especiales: 'SOLO ALFANUMERICOS Y CARACTERES -\/\\.,;@ -#():',
    correctDate:'FORMATO REQUERIDO DD/MM/YYYY',
    checkDigit:'CHECK-DIGIT',
    LaitudeNotReq: 'Solo Coordenadas',
    metricsWSNotReq: 'Solo Coordenadas',
    textEspecials: 'SOLO TEXTO',  
    alphaNum: 'SOLO ALFANUMERICOS',
    verifyNumber: 'CAMPO REQUERIDO',
    siglasAlphanumeric: 'SOLO ALFANUMERICOS',
    credentials2: 'FORMATO REQUERIDO VXXXXXXX',
    validMac: 'DIRECCION MAC INVALIDA',
    notsameAsOrigin: "Puerto de destino no puede ser igual al de origen",
    notsameAsDestiny: "Puerto de procedencia no puede ser igual al de destino",
    codeDelay: 'SOLO ALFANUMERICOS Y CARACTERES ( )',
    onlyAlphanumeric2: 'Solo Alfanumericos',
    manufactererNotReq: 'SOLO TEXTO',
    IMEINotReq: 'SOLO NUMEROS',
    resolution: 'SOLO NUMEROS',
    invalidCode: ' Código no válido',
    textNotReq: 'SOLO TEXTO',
    textareaNotReq: 'SOLO TEXTO Y CARACTERES , . * + - # / $ ! ()',
    invalidCodeImdg: 'LA PRIMERA SIGLA DEBE COINCIDIR CON LA CLASE IMDG. SOLO NUMEROS Y EL CARACTER . ',
    alphaNumCustom: 'SOLO NUMEROS Y CARACTER -',
    numberId: 'NUMERO DE DOCUMENTO - FORMATO REQUERIDO V99999999',
    validationSeal: 'SOLO ALFANUMERICOS Y CARACTER -',
    temperature: 'SOLO NUMEROS',
    notsameAsPol: "POL no puede ser igual al POD",
    notsameAsPod: "POD no puede ser igual al POL",
    notAvailableTotal: 'Cantidad de Toneladas a Asignar no debe ser mayor a la Disponibilidad de la Bodega',
    notAvailableBl: 'Cantidad de Toneladas a Asignar no puede ser mayor a lo disponible a asignar por Bl',
    exceedtActualInventory: 'Cantidad de peso no debe ser mayor a la Disponibilidad del inventario actual',
    exceedEstimatedToShip: 'Cantidad de peso no debe ser menor al estimado a embarcar',
    nameLength: 'LONGITUD DE LA PALABRA INVÁLIDA',
    RangeValidate: 'RANGO DE FECHA INVALIDO',
    onlyAlphaguion: 'SOLO TEXTO Y GUIONES',
    alphaNumSpecials2: 'SOLO ALFANUMERICO Y CARACTERES',
    notOnlyNumbers: 'SOLO ALFANUMERICO Y CARACTERES',
    metricsNotReq: 'SOLO NUMEROS',
    onlyRoute: 'FORMATO REQUERIDO URL',
    onlyIcon: 'FORMATO REQUERIDO .SVG .PNG .JPG',
    onlyCodeVersion:'SOLO TEXTO, NUMEROS Y CARACTERES  . - _',
    resolutionFormat:'FORMATO REQUERIDO 10.00',
    onlyApkFileName:'FORMATO REQUERIDO .APK',
    verifyNumClaseImdg: 'VERIFICAR NUMERO DE CLASE IMDG',
    onlyHsCode: 'SOLO TEXTO, NUMEROS Y CARACTERES , . - ',
    DocumentTypeActivity: 'EL TIPO DE DOCUMENTO NO CORRESPONDE A LA ACTIVIDAD',
    quantityRemainErrorBl: 'LA CANTIDAD ES MAYOR AL REMANENTE DEL BL',
    quantityRemainError: 'LA CANTIDAD ES MAYOR AL REMANENTE',
    quantityRemainPackaging: 'LA CANTIDAD ES MAYOR AL REMANENTE DEL EMBALAJE',
    NotRubro: 'ESTA MERCANCIA NO ESTA ASOCIADA A UN RUBRO',
    CertifiedQuantity: 'LA CANTIDAD PLANIFICADA NO PUEDE SER MENOR A LA CERIFICADA',
    ConsigneeBl: 'NO COINCIDE CON EL CONSIGNATARIO DEL BL',
    OnlyOneImdgPerPackage: 'SOLO UN IMDG POR EMBALAJE',
    StatusTabCleared: 'SE DEBE DE LIMPIAR EL FORMULARIO DEL TAB DE ESTATUS',
    PlannedLessReceived: 'LA CANTIDAD PLANIFICADA NO PUEDE SER MENOR A LO RECEPCIONADO',
    minDateCertificate: 'LA FECHA Y HORA NO DEBE SER MENOR A LA PRIMERA RECEPCIÓN, POR FAVOR VERIFIQUE',
    minDateStatusCertificate_LastReception: 'LA FECHA Y HORA NO DEBE SER MENOR O IGUAL A LA ULTIMA RECEPCIÓN, POR FAVOR VERIFIQUE',
    minDateStatusCertificate: 'LA FECHA Y HORA NO DEBE SER MENOR O IGUAL AL ACTA DE RECEPCIÓN, POR FAVOR VERIFIQUE',
  },
  developed: {
    by: "Desarrollado por",
  },
}

export {
  es
};